import React, { useEffect, useContext, useState } from "react";
import { UserRolesPermission } from "../WebApiServices/RolesPermission.service";
import { HTTPResponse, ROLE_TYPE, ToastMessageType } from "../Helpers/Enums";
import BreadcrumbComponent from "../Components/BreadcrumbComponent";
import { GetLocalStorageData, GetLoggedInUserID, changePageTitle } from "../Helpers/Utilities";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import { RolesPermissionErrorMessage, ToastMessages } from "../Helpers/HelperText";
import ErrorField from "../Components/ErrorField";
import ErrorPage from "./ErrorPage";

const RolesAndPermission = () => {
    const roleId = Number(GetLocalStorageData("roleID"));
    const { LogOut } = useUIContext();
    const { ToggleLoader, ShowToastMessage } = useContext(AppContext);

    const [adminPermission, setAdminPermission] = useState({
        ManageEmp: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageRolesPermission: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageProject: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        TimeLog: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        MyTask: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        }
    });

    const [projectLeadPermission, setProjectLeadPermission] = useState({
        ManageEmp: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageRolesPermission: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageProject: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        TimeLog: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        MyTask: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        }
    });

    const [employeePermission, setEmployeePermission] = useState({
        ManageEmp: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageRolesPermission: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageProject: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        TimeLog: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        MyTask: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        }
    });

    //SYJ Load Default Permission
    const [adminPermissionDefault, setAdminPermissionDefault] = useState({
        ManageEmp: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageRolesPermission: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageProject: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        TimeLog: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        MyTask: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        }
    });

    const [projectLeadPermissionDefault, setProjectLeadPermissionDefault] = useState({
        ManageEmp: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageRolesPermission: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageProject: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        TimeLog: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        MyTask: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        }
    });

    const [employeePermissionDefault, setEmployeePermissionDefault] = useState({
        ManageEmp: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageRolesPermission: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageProject: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        TimeLog: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        MyTask: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        }
    });

    //SYJ Handle saved Permission to New [] state to handle toggle.
    const [adminPermissionDefaultShow, setAdminPermissionDefaultShow] = useState({
        ManageEmp: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageRolesPermission: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageProject: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        TimeLog: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        MyTask: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        }
    });

    const [projectLeadPermissionDefaultShow, setProjectLeadPermissionDefaultShow] = useState({
        ManageEmp: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageRolesPermission: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageProject: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        TimeLog: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        MyTask: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        }
    });

    const [employeePermissionDefaultShow, setEmployeePermissionDefaultShow] = useState({
        ManageEmp: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageRolesPermission: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        ManageProject: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        TimeLog: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        },
        MyTask: {
            add: 0,
            edit: 0,
            view: 0,
            delete: 0,

            addOther: 0,
            editOther: 0,
            viewOther: 0,
            deleteOther: 0
        }
    });

    const [isAdminOptionEmptyError, setIsAdminOptionEmptyError] = useState(false);
    const [isPLOptionEmptyError, setIsPLOptionEmptyError] = useState(false);
    const [isEmployeeOptionEmptyError, setIsEmployeeOptionEmptyError] = useState(false);
    const [isSave, setIsSave] = useState(false);

    const handleAdminStatusChange = (category, action) => {
        return {
            ...adminPermission,
            [category]: {
                ...adminPermission[category],
                [action]: adminPermission[category][action] === 1 ? 0 : 1
            },
        };
    };

    const handleRPStatusChange = (category, action) => {
        return {
            ...projectLeadPermission,
            [category]: {
                ...projectLeadPermission[category],
                [action]: projectLeadPermission[category][action] === 1 ? 0 : 1
            },
        };
    };

    const handleEmpStatusChange = (category, action) => {

        return {
            ...employeePermission,
            [category]: {
                ...employeePermission[category],
                [action]: employeePermission[category][action] === 1 ? 0 : 1
            }
        };
    };

    useEffect(() => {
        changePageTitle("Roles Permissions");
        GetAllRolesPermission();
    }, [isSave]);


    const GetAllRolesPermission = async () => {
        try {
            ToggleLoader(true);
            //Default Array llist to filter data
            let adminList = [];
            let adminOtherList = [];
            let leadList = [];
            let leadOtherList = [];
            let employeeList = [];
            let employeeOtherList = [];
            const responseDefaultRP = await UserRolesPermission.GetDefaultRolePermissions();
            const responseUserSaveRP = await UserRolesPermission.GetUserSavedRolePermissions();



            // Function to get the list of role IDs that exist in the user saved role permissions data
            const existingRoleIds = responseUserSaveRP.data.map(item => item.roleId);


            // Bind data based on the existing role IDs
            if (existingRoleIds.includes(1)) {
                adminList = responseUserSaveRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 1);
                adminOtherList = responseUserSaveRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 2);
            }
            if (existingRoleIds.includes(2)) {
                employeeList = responseUserSaveRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 1);
                employeeOtherList = responseUserSaveRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 2);
            }
            if (existingRoleIds.includes(3)) {
                leadList = responseUserSaveRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 1);
                leadOtherList = responseUserSaveRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 2);
            }


            // If no role IDs 1, 2, or 3 data is present, bind the default list
            if (adminList.length === 0) {
                adminList = responseDefaultRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 1);
            }
            if (employeeList.length === 0) {
                employeeList = responseDefaultRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 1);
            }
            if (leadList.length === 0) {
                leadList = responseDefaultRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 1);
            }

            // If no role IDs 1, 2, or 3 data is present, bind the default list
            if (adminOtherList.length === 0) {
                adminOtherList = responseDefaultRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 2);
            }
            if (employeeOtherList.length === 0) {
                employeeOtherList = responseDefaultRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 2);
            }
            if (leadOtherList.length === 0) {
                leadOtherList = responseDefaultRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 2);
            }



            if (adminList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setAdminPermission({
                    ManageEmp: {
                        add: adminList.some(item => item.meadd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.meedit === 0) ? 0 : 1,
                        view: adminList.some(item => item.meview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        add: adminList.some(item => item.mrpadd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.mrpedit === 0) ? 0 : 1,
                        view: adminList.some(item => item.mrpview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        add: adminList.some(item => item.mpadd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.mpedit === 0) ? 0 : 1,
                        view: adminList.some(item => item.mpview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        add: adminList.some(item => item.tladd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.tledit === 0) ? 0 : 1,
                        view: adminList.some(item => item.tlview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        add: adminList.some(item => item.mtadd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.mtedit === 0) ? 0 : 1,
                        view: adminList.some(item => item.mtview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                });
            }

            if (leadList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setProjectLeadPermission({
                    ManageEmp: {
                        add: leadList.some(item => item.meadd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.meedit === 0) ? 0 : 1,
                        view: leadList.some(item => item.meview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        add: leadList.some(item => item.mrpadd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.mrpedit === 0) ? 0 : 1,
                        view: leadList.some(item => item.mrpview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        add: leadList.some(item => item.mpadd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.mpedit === 0) ? 0 : 1,
                        view: leadList.some(item => item.mpview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        add: leadList.some(item => item.tladd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.tledit === 0) ? 0 : 1,
                        view: leadList.some(item => item.tlview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        add: leadList.some(item => item.mtadd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.mtedit === 0) ? 0 : 1,
                        view: leadList.some(item => item.mtview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                });
            }

            if (employeeList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setEmployeePermission({
                    ManageEmp: {
                        add: employeeList.some(item => item.meadd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.meedit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.meview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        add: employeeList.some(item => item.mrpadd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.mrpedit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.mrpview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        add: employeeList.some(item => item.mpadd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.mpedit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.mpview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        add: employeeList.some(item => item.tladd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.tledit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.tlview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        add: employeeList.some(item => item.mtadd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.mtedit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.mtview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                });
            }


            if (adminOtherList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setAdminPermission(prevState => ({
                    ...prevState,
                    ManageEmp: {
                        ...prevState.ManageEmp,
                        addOther: adminOtherList.some(item => item.meadd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.meedit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.meview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        ...prevState.ManageRolesPermission,
                        addOther: adminOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.mrpview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        ...prevState.ManageProject,
                        addOther: adminOtherList.some(item => item.mpadd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.mpedit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.mpview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        ...prevState.TimeLog,
                        addOther: adminOtherList.some(item => item.tladd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.tledit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.tlview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        ...prevState.MyTask,
                        addOther: adminOtherList.some(item => item.mtadd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.mtedit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.mtview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                }));
            }

            if (leadOtherList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setProjectLeadPermission(prevState => ({
                    ...prevState,
                    ManageEmp: {
                        ...prevState.ManageEmp,
                        addOther: leadOtherList.some(item => item.meadd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.meedit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.meview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        ...prevState.ManageRolesPermission,
                        addOther: leadOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.mrpview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        ...prevState.ManageProject,
                        addOther: leadOtherList.some(item => item.mpadd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.mpedit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.mpview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        ...prevState.TimeLog,
                        addOther: leadOtherList.some(item => item.tladd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.tledit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.tlview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        ...prevState.MyTask,
                        addOther: leadOtherList.some(item => item.mtadd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.mtedit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.mtview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                }));
            }

            if (employeeOtherList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setEmployeePermission(prevState => ({
                    ManageEmp: {
                        ...prevState.ManageEmp,
                        addOther: employeeOtherList.some(item => item.meadd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.meedit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.meview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        ...prevState.ManageRolesPermission,
                        addOther: employeeOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.mrpview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        ...prevState.ManageProject,
                        addOther: employeeOtherList.some(item => item.mpadd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.mpedit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.mpview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        ...prevState.TimeLog,
                        addOther: employeeOtherList.some(item => item.tladd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.tledit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.tlview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        ...prevState.MyTask,
                        addOther: employeeOtherList.some(item => item.mtadd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.mtedit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.mtview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                }));
            }

            //SYJ - Default Permission if cancelled
            if (adminList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setAdminPermissionDefaultShow({
                    ManageEmp: {
                        add: adminList.some(item => item.meadd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.meedit === 0) ? 0 : 1,
                        view: adminList.some(item => item.meview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        add: adminList.some(item => item.mrpadd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.mrpedit === 0) ? 0 : 1,
                        view: adminList.some(item => item.mrpview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        add: adminList.some(item => item.mpadd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.mpedit === 0) ? 0 : 1,
                        view: adminList.some(item => item.mpview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        add: adminList.some(item => item.tladd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.tledit === 0) ? 0 : 1,
                        view: adminList.some(item => item.tlview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        add: adminList.some(item => item.mtadd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.mtedit === 0) ? 0 : 1,
                        view: adminList.some(item => item.mtview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                });
            }

            if (leadList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setProjectLeadPermissionDefaultShow({
                    ManageEmp: {
                        add: leadList.some(item => item.meadd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.meedit === 0) ? 0 : 1,
                        view: leadList.some(item => item.meview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        add: leadList.some(item => item.mrpadd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.mrpedit === 0) ? 0 : 1,
                        view: leadList.some(item => item.mrpview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        add: leadList.some(item => item.mpadd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.mpedit === 0) ? 0 : 1,
                        view: leadList.some(item => item.mpview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        add: leadList.some(item => item.tladd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.tledit === 0) ? 0 : 1,
                        view: leadList.some(item => item.tlview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        add: leadList.some(item => item.mtadd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.mtedit === 0) ? 0 : 1,
                        view: leadList.some(item => item.mtview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                });
            }

            if (employeeList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setEmployeePermissionDefaultShow({
                    ManageEmp: {
                        add: employeeList.some(item => item.meadd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.meedit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.meview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        add: employeeList.some(item => item.mrpadd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.mrpedit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.mrpview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        add: employeeList.some(item => item.mpadd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.mpedit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.mpview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        add: employeeList.some(item => item.tladd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.tledit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.tlview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        add: employeeList.some(item => item.mtadd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.mtedit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.mtview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                });
            }

            //SYJ - Others Default Permission if cancelled
            if (adminOtherList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setAdminPermissionDefaultShow(prevState => ({
                    ManageEmp: {
                        ...prevState.ManageEmp,
                        addOther: adminOtherList.some(item => item.meadd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.meedit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.meview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        ...prevState.ManageRolesPermission,
                        addOther: adminOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.mrpview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        ...prevState.ManageProject,
                        addOther: adminOtherList.some(item => item.mpadd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.mpedit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.mpview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        ...prevState.TimeLog,
                        addOther: adminOtherList.some(item => item.tladd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.tledit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.tlview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        ...prevState.MyTask,
                        addOther: adminOtherList.some(item => item.mtadd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.mtedit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.mtview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                }));
            }

            if (leadOtherList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setProjectLeadPermissionDefaultShow(prevState => ({
                    ManageEmp: {
                        ...prevState.ManageEmp,
                        addOther: leadOtherList.some(item => item.meadd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.meedit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.meview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        ...prevState.ManageRolesPermission,
                        addOther: leadOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.mrpview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        ...prevState.ManageProject,
                        addOther: leadOtherList.some(item => item.mpadd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.mpedit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.mpview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        ...prevState.TimeLog,
                        addOther: leadOtherList.some(item => item.tladd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.tledit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.tlview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        ...prevState.MyTask,
                        addOther: leadOtherList.some(item => item.mtadd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.mtedit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.mtview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                }));
            }

            if (employeeOtherList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setEmployeePermissionDefaultShow(prevState => ({
                    ManageEmp: {
                        ...prevState.ManageEmp,
                        addOther: employeeOtherList.some(item => item.meadd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.meedit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.meview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        ...prevState.ManageRolesPermission,
                        addOther: employeeOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.mrpview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        ...prevState.ManageProject,
                        addOther: employeeOtherList.some(item => item.mpadd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.mpedit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.mpview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        ...prevState.TimeLog,
                        addOther: employeeOtherList.some(item => item.tladd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.tledit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.tlview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        ...prevState.MyTask,
                        addOther: employeeOtherList.some(item => item.mtadd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.mtedit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.mtview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                }));
            }


            //SYJ - Default Permission if cancelled
            if (adminList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setAdminPermissionDefault({
                    ManageEmp: {
                        add: adminList.some(item => item.meadd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.meedit === 0) ? 0 : 1,
                        view: adminList.some(item => item.meview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        add: adminList.some(item => item.mrpadd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.mrpedit === 0) ? 0 : 1,
                        view: adminList.some(item => item.mrpview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        add: adminList.some(item => item.mpadd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.mpedit === 0) ? 0 : 1,
                        view: adminList.some(item => item.mpview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        add: adminList.some(item => item.tladd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.tledit === 0) ? 0 : 1,
                        view: adminList.some(item => item.tlview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        add: adminList.some(item => item.mtadd === 0) ? 0 : 1,
                        edit: adminList.some(item => item.mtedit === 0) ? 0 : 1,
                        view: adminList.some(item => item.mtview === 0) ? 0 : 1,
                        delete: adminList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                });
            }

            if (leadList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setProjectLeadPermissionDefault({
                    ManageEmp: {
                        add: leadList.some(item => item.meadd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.meedit === 0) ? 0 : 1,
                        view: leadList.some(item => item.meview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        add: leadList.some(item => item.mrpadd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.mrpedit === 0) ? 0 : 1,
                        view: leadList.some(item => item.mrpview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        add: leadList.some(item => item.mpadd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.mpedit === 0) ? 0 : 1,
                        view: leadList.some(item => item.mpview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        add: leadList.some(item => item.tladd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.tledit === 0) ? 0 : 1,
                        view: leadList.some(item => item.tlview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        add: leadList.some(item => item.mtadd === 0) ? 0 : 1,
                        edit: leadList.some(item => item.mtedit === 0) ? 0 : 1,
                        view: leadList.some(item => item.mtview === 0) ? 0 : 1,
                        delete: leadList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                });
            }

            if (employeeList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setEmployeePermissionDefault({
                    ManageEmp: {
                        add: employeeList.some(item => item.meadd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.meedit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.meview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        add: employeeList.some(item => item.mrpadd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.mrpedit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.mrpview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        add: employeeList.some(item => item.mpadd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.mpedit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.mpview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        add: employeeList.some(item => item.tladd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.tledit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.tlview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        add: employeeList.some(item => item.mtadd === 0) ? 0 : 1,
                        edit: employeeList.some(item => item.mtedit === 0) ? 0 : 1,
                        view: employeeList.some(item => item.mtview === 0) ? 0 : 1,
                        delete: employeeList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                });
            }

            //SYJ - Default Permission if cancelled
            if (adminOtherList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setAdminPermissionDefault(prevState => ({
                    ManageEmp: {
                        ...prevState.ManageEmp,
                        addOther: adminOtherList.some(item => item.meadd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.meedit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.meview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        ...prevState.ManageRolesPermission,
                        addOther: adminOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.mrpview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        ...prevState.ManageProject,
                        addOther: adminOtherList.some(item => item.mpadd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.mpedit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.mpview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        ...prevState.TimeLog,
                        addOther: adminOtherList.some(item => item.tladd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.tledit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.tlview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        ...prevState.MyTask,
                        addOther: adminOtherList.some(item => item.mtadd === 0) ? 0 : 1,
                        editOther: adminOtherList.some(item => item.mtedit === 0) ? 0 : 1,
                        viewOther: adminOtherList.some(item => item.mtview === 0) ? 0 : 1,
                        deleteOther: adminOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                }));
            }

            if (leadOtherList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setProjectLeadPermissionDefault(prevState => ({
                    ManageEmp: {
                        ...prevState.MyTask,
                        addOther: leadOtherList.some(item => item.meadd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.meedit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.meview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        ...prevState.ManageRolesPermission,
                        addOther: leadOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.mrpview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        ...prevState.ManageProject,
                        addOther: leadOtherList.some(item => item.mpadd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.mpedit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.mpview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        ...prevState.TimeLog,
                        addOther: leadOtherList.some(item => item.tladd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.tledit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.tlview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        ...prevState.MyTask,
                        addOther: leadOtherList.some(item => item.mtadd === 0) ? 0 : 1,
                        editOther: leadOtherList.some(item => item.mtedit === 0) ? 0 : 1,
                        viewOther: leadOtherList.some(item => item.mtview === 0) ? 0 : 1,
                        deleteOther: leadOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                }));
            }

            if (employeeOtherList.length > 0) {
                // Update the adminPermission state with the filtered admin list
                setEmployeePermissionDefault(prevState => ({
                    ManageEmp: {
                        ...prevState.ManageEmp,
                        addOther: employeeOtherList.some(item => item.meadd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.meedit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.meview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.medelete === 0) ? 0 : 1,
                    },
                    ManageRolesPermission: {
                        ...prevState.ManageRolesPermission,
                        addOther: employeeOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.mrpview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
                    },
                    ManageProject: {
                        ...prevState.ManageProject,
                        addOther: employeeOtherList.some(item => item.mpadd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.mpedit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.mpview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
                    },
                    TimeLog: {
                        ...prevState.TimeLog,
                        addOther: employeeOtherList.some(item => item.tladd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.tledit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.tlview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.tldelete === 0) ? 0 : 1,
                    },
                    MyTask: {
                        ...prevState.MyTask,
                        addOther: employeeOtherList.some(item => item.mtadd === 0) ? 0 : 1,
                        editOther: employeeOtherList.some(item => item.mtedit === 0) ? 0 : 1,
                        viewOther: employeeOtherList.some(item => item.mtview === 0) ? 0 : 1,
                        deleteOther: employeeOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
                    }
                }));
            }


            ToggleLoader(false);
        } catch (error) {
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
    };


    // Function to validate Admin Permission
    const validateAdminRole = () => {
        const haserrors = false;

        // Check if any permission in ManageEmp is 0
        const isManageEmpZero = Object.values(adminPermission.ManageEmp).some(value => value === 0);

        // Check if any permission in ManageRolesPermission is 0
        const isManageRolesPermissionZero = Object.values(adminPermission.ManageRolesPermission).some(value => value === 0);

        // Check if any permission in ManageProject is 0
        const isProjectPermissionZero = Object.values(adminPermission.ManageProject).some(value => value === 0);

        const isTimeLogPermissionZero = Object.values(adminPermission.TimeLog).some(value => value === 0);

        const isMyTaskPermissionZero = Object.values(adminPermission.MyTask).some(value => value === 0);


        if (isManageEmpZero || isManageRolesPermissionZero || isProjectPermissionZero || isTimeLogPermissionZero || isMyTaskPermissionZero) {
            setIsAdminOptionEmptyError(true);
            haserrors = true;
        }
        return haserrors;
    };


    // Function to validate Employee Permission
    const validateEmployeeRole = () => {
        const haserrors = false;

        // Check if any permission in ManageEmp is 0
        const isManageEmpZero = Object.values(employeePermission.ManageEmp).some(value => value === 0);

        // Check if any permission in ManageRolesPermission is 0
        const isManageRolesPermissionZero = Object.values(employeePermission.ManageRolesPermission).some(value => value === 0);

        // Check if any permission in ManageProject is 0
        const isProjectPermissionZero = Object.values(employeePermission.ManageProject).some(value => value === 0);

        const isTimeLogPermissionZero = Object.values(employeePermission.TimeLog).some(value => value === 0);

        const isMyTaskPermissionZero = Object.values(employeePermission.MyTask).some(value => value === 0);


        if (isManageEmpZero || isManageRolesPermissionZero || isProjectPermissionZero || isTimeLogPermissionZero || isMyTaskPermissionZero) {
            setIsEmployeeOptionEmptyError(true);
            haserrors = true;
        }
        return haserrors;
    };

    // Function to validate Project Lead Permission
    const validatePLRole = () => {
        const haserrors = false;

        // Check if any permission in ManageEmp is 0
        const isManageEmpZero = Object.values(projectLeadPermission.ManageEmp).some(value => value === 0);

        // Check if any permission in ManageRolesPermission is 0
        const isManageRolesPermissionZero = Object.values(projectLeadPermission.ManageRolesPermission).some(value => value === 0);

        // Check if any permission in ManageProject is 0
        const isProjectPermissionZero = Object.values(projectLeadPermission.ManageProject).some(value => value === 0);

        const isTimeLogPermissionZero = Object.values(projectLeadPermission.TimeLog).some(value => value === 0);

        const isMyTaskPermissionZero = Object.values(projectLeadPermission.MyTask).some(value => value === 0);


        if (isManageEmpZero || isManageRolesPermissionZero || isProjectPermissionZero || isTimeLogPermissionZero || isMyTaskPermissionZero) {
            setIsAdminOptionEmptyError(true);
            haserrors = true;
        }
        return haserrors;
    };

    const GenerateReports = async () => {
        try {
            let res = await UserRolesPermission.GenerateUserWorkLogReport();
            const data = await res.data; // Convert response data to text
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Create blob from text data
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'UserWorkLogReport.csv'); // Change the file name if necessary
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail)
        }
    }

    //Saving of Roles and Permission
    const SaveAdminRole = async () => {
        try {
            ToggleLoader(true);
            // if (validateAdminRole) {
            const DataToSave = {
                Meadd: adminPermission.ManageEmp.add,
                Meedit: adminPermission.ManageEmp.edit,
                Meview: adminPermission.ManageEmp.view,
                Medelete: adminPermission.ManageEmp.delete,
                Mrpadd: adminPermission.ManageRolesPermission.add,
                Mrpedit: adminPermission.ManageRolesPermission.edit,
                Mrpview: adminPermission.ManageRolesPermission.view,
                Mrpdelete: adminPermission.ManageRolesPermission.delete,
                Mpadd: adminPermission.ManageProject.add,
                Mpedit: adminPermission.ManageProject.edit,
                Mpview: adminPermission.ManageProject.view,
                Mpdelete: adminPermission.ManageProject.delete,
                Tladd: adminPermission.TimeLog.add,
                Tledit: adminPermission.TimeLog.edit,
                Tlview: adminPermission.TimeLog.view,
                Tldelete: adminPermission.TimeLog.delete,
                Mtadd: adminPermission.MyTask.add,
                Mtedit: adminPermission.MyTask.edit,
                Mtview: adminPermission.MyTask.view,
                Mtdelete: adminPermission.MyTask.delete,
                RoleId: ROLE_TYPE.ADMIN,
                UserId: GetLoggedInUserID(),
            }
            let response = await UserRolesPermission.SaveAdminRolesPermission(DataToSave);
            if (response.data && response.status === HTTPResponse.OK) {
                ShowToastMessage(RolesPermissionErrorMessage.PermissionSave, ToastMessageType.Success)
                setIsSave(response.data);
                ToggleLoader(false);
            }
            if (response.status === HTTPResponse.Unauthorized) {
                LogOut();
                ToggleLoader(false);
            }
            // }
        }
        catch {
            ToggleLoader(false);
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail)
        }
    }

    const SavePLRole = async () => {
        try {
            ToggleLoader(true);
            // if (validatePLRole) {

            const DataToSave = {
                Meadd: projectLeadPermission.ManageEmp.add,
                Meedit: projectLeadPermission.ManageEmp.edit,
                Meview: projectLeadPermission.ManageEmp.view,
                Medelete: projectLeadPermission.ManageEmp.delete,
                Mrpadd: projectLeadPermission.ManageRolesPermission.add,
                Mrpedit: projectLeadPermission.ManageRolesPermission.edit,
                Mrpview: projectLeadPermission.ManageRolesPermission.view,
                Mrpdelete: projectLeadPermission.ManageRolesPermission.delete,
                Mpadd: projectLeadPermission.ManageProject.add,
                Mpedit: projectLeadPermission.ManageProject.edit,
                Mpview: projectLeadPermission.ManageProject.view,
                Mpdelete: projectLeadPermission.ManageProject.delete,
                Tladd: projectLeadPermission.TimeLog.add,
                Tledit: projectLeadPermission.TimeLog.edit,
                Tlview: projectLeadPermission.TimeLog.view,
                Tldelete: projectLeadPermission.TimeLog.delete,
                Mtadd: projectLeadPermission.MyTask.add,
                Mtedit: projectLeadPermission.MyTask.edit,
                Mtview: projectLeadPermission.MyTask.view,
                Mtdelete: projectLeadPermission.MyTask.delete,
                RoleId: ROLE_TYPE.LEAD,
                UserId: GetLoggedInUserID(),
            }
            let response = await UserRolesPermission.SavePLRolesPermission(DataToSave);
            if (response.data && response.status === HTTPResponse.OK) {
                ShowToastMessage(RolesPermissionErrorMessage.PermissionSave, ToastMessageType.Success)
                setIsSave(response.data);
                ToggleLoader(false);
            }
            if (response.status === HTTPResponse.Unauthorized) {
                LogOut();
                ToggleLoader(false);
            }
            // }
        }
        catch {
            ToggleLoader(false);
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail)
        }
    }

    //Set to Default Permission
    const LoadDefaultAdminPermission = async () => {
        //navigate("/ManageUsers"); 
        setAdminPermission(adminPermissionDefault);
    }

    const LoadDefaultPLPermission = async () => {
        //navigate("/ManageUsers");
        setProjectLeadPermission(projectLeadPermissionDefault);
    }

    const LoadDefaultEmpPermission = async () => {
        //navigate("/ManageUsers");
        setEmployeePermission(employeePermissionDefault);
    }

    const LoadAllDefaultPermission = async () => {
        //navigate("/ManageUsers");
        setEmployeePermission(employeePermissionDefault);
        setAdminPermission(adminPermissionDefault);
        setProjectLeadPermission(projectLeadPermissionDefault);
    }

    // const SaveEmployeeRole = async () => {
    //     try {
    //         ToggleLoader(true);
    //         // if (validateEmployeeRole) {

    //         const DataToSave = {
    //             Meadd: employeePermission.ManageEmp.add,
    //             Meedit: employeePermission.ManageEmp.edit,
    //             Meview: employeePermission.ManageEmp.view,
    //             Medelete: employeePermission.ManageEmp.delete,
    //             Mrpadd: employeePermission.ManageRolesPermission.add,
    //             Mrpedit: employeePermission.ManageRolesPermission.edit,
    //             Mrpview: employeePermission.ManageRolesPermission.view,
    //             Mrpdelete: employeePermission.ManageRolesPermission.delete,
    //             Mpadd: employeePermission.ManageProject.add,
    //             Mpedit: employeePermission.ManageProject.edit,
    //             Mpview: employeePermission.ManageProject.view,
    //             Mpdelete: employeePermission.ManageProject.delete,
    //             Tladd: employeePermission.TimeLog.add,
    //             Tledit: employeePermission.TimeLog.edit,
    //             Tlview: employeePermission.TimeLog.view,
    //             Tldelete: employeePermission.TimeLog.delete,
    //             Mtadd: employeePermission.MyTask.add,
    //             Mtedit: employeePermission.MyTask.edit,
    //             Mtview: employeePermission.MyTask.view,
    //             Mtdelete: employeePermission.MyTask.delete,
    //             RoleId: ROLE_TYPE.EMPLOYEE,
    //             UserId: GetLoggedInUserID(),
    //         }
    //         let response = await UserRolesPermission.SaveEmployeeRolesPermission(DataToSave);
    //         if (response.data && response.status === HTTPResponse.OK) {
    //             ShowToastMessage(RolesPermissionErrorMessage.PermissionSave, ToastMessageType.Success)
    //             setIsSave(response.data);
    //             ToggleLoader(false);
    //         }
    //         if (response.status === HTTPResponse.Unauthorized) {
    //             LogOut();
    //             ToggleLoader(false);
    //         }
    //         // }
    //     }
    //     catch {
    //         ToggleLoader(false);
    //         ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail)
    //     }
    // }

    const SaveAllRolesPermission = async () => {
        try {
            let response = false;
            ToggleLoader(true);
            // if (validateEmployeeRole) {

            // Data for Admin "self" (without "Other")
            const selfAdminDataToSave = {
                Meadd: adminPermission.ManageEmp.add,
                Meedit: adminPermission.ManageEmp.edit,
                Meview: adminPermission.ManageEmp.view,
                Medelete: adminPermission.ManageEmp.delete,
                Mrpadd: adminPermission.ManageRolesPermission.add,
                Mrpedit: adminPermission.ManageRolesPermission.edit,
                Mrpview: adminPermission.ManageRolesPermission.view,
                Mrpdelete: adminPermission.ManageRolesPermission.delete,
                Mpadd: adminPermission.ManageProject.add,
                Mpedit: adminPermission.ManageProject.edit,
                Mpview: adminPermission.ManageProject.view,
                Mpdelete: adminPermission.ManageProject.delete,
                Tladd: adminPermission.TimeLog.add,
                Tledit: adminPermission.TimeLog.edit,
                Tlview: adminPermission.TimeLog.view,
                Tldelete: adminPermission.TimeLog.delete,
                Mtadd: adminPermission.MyTask.add,
                Mtedit: adminPermission.MyTask.edit,
                Mtview: adminPermission.MyTask.view,
                Mtdelete: adminPermission.MyTask.delete,
                RoleId: ROLE_TYPE.ADMIN,
                UserId: GetLoggedInUserID(),
                PermissionType: "Self"
            };

            // Data for Admin "others" (with "Other")
            const othersAdminDataToSave = {
                Meadd: adminPermission.ManageEmp.addOther,
                Meedit: adminPermission.ManageEmp.editOther,
                Meview: adminPermission.ManageEmp.viewOther,
                Medelete: adminPermission.ManageEmp.deleteOther,
                Mrpadd: adminPermission.ManageRolesPermission.addOther,
                Mrpedit: adminPermission.ManageRolesPermission.editOther,
                Mrpview: adminPermission.ManageRolesPermission.viewOther,
                Mrpdelete: adminPermission.ManageRolesPermission.deleteOther,
                Mpadd: adminPermission.ManageProject.addOther,
                Mpedit: adminPermission.ManageProject.editOther,
                Mpview: adminPermission.ManageProject.viewOther,
                Mpdelete: adminPermission.ManageProject.deleteOther,
                Tladd: adminPermission.TimeLog.addOther,
                Tledit: adminPermission.TimeLog.editOther,
                Tlview: adminPermission.TimeLog.viewOther,
                Tldelete: adminPermission.TimeLog.deleteOther,
                Mtadd: adminPermission.MyTask.addOther,
                Mtedit: adminPermission.MyTask.editOther,
                Mtview: adminPermission.MyTask.viewOther,
                Mtdelete: adminPermission.MyTask.deleteOther,
                RoleId: ROLE_TYPE.ADMIN,
                UserId: GetLoggedInUserID(),
                PermissionType: "Others"
            };

            // Data for Project Lead "self" (without "Other")
            const selfPLDataToSave = {
                Meadd: projectLeadPermission.ManageEmp.add,
                Meedit: projectLeadPermission.ManageEmp.edit,
                Meview: projectLeadPermission.ManageEmp.view,
                Medelete: projectLeadPermission.ManageEmp.delete,
                Mrpadd: projectLeadPermission.ManageRolesPermission.add,
                Mrpedit: projectLeadPermission.ManageRolesPermission.edit,
                Mrpview: projectLeadPermission.ManageRolesPermission.view,
                Mrpdelete: projectLeadPermission.ManageRolesPermission.delete,
                Mpadd: projectLeadPermission.ManageProject.add,
                Mpedit: projectLeadPermission.ManageProject.edit,
                Mpview: projectLeadPermission.ManageProject.view,
                Mpdelete: projectLeadPermission.ManageProject.delete,
                Tladd: projectLeadPermission.TimeLog.add,
                Tledit: projectLeadPermission.TimeLog.edit,
                Tlview: projectLeadPermission.TimeLog.view,
                Tldelete: projectLeadPermission.TimeLog.delete,
                Mtadd: projectLeadPermission.MyTask.add,
                Mtedit: projectLeadPermission.MyTask.edit,
                Mtview: projectLeadPermission.MyTask.view,
                Mtdelete: projectLeadPermission.MyTask.delete,
                RoleId: ROLE_TYPE.LEAD,
                UserId: GetLoggedInUserID(),
                PermissionType: "Self"
            };

            // Data for Project Lead "others" (with "Other")
            const othersPLDataToSave = {
                Meadd: projectLeadPermission.ManageEmp.addOther,
                Meedit: projectLeadPermission.ManageEmp.editOther,
                Meview: projectLeadPermission.ManageEmp.viewOther,
                Medelete: projectLeadPermission.ManageEmp.deleteOther,
                Mrpadd: projectLeadPermission.ManageRolesPermission.addOther,
                Mrpedit: projectLeadPermission.ManageRolesPermission.editOther,
                Mrpview: projectLeadPermission.ManageRolesPermission.viewOther,
                Mrpdelete: projectLeadPermission.ManageRolesPermission.deleteOther,
                Mpadd: projectLeadPermission.ManageProject.addOther,
                Mpedit: projectLeadPermission.ManageProject.editOther,
                Mpview: projectLeadPermission.ManageProject.viewOther,
                Mpdelete: projectLeadPermission.ManageProject.deleteOther,
                Tladd: projectLeadPermission.TimeLog.addOther,
                Tledit: projectLeadPermission.TimeLog.editOther,
                Tlview: projectLeadPermission.TimeLog.viewOther,
                Tldelete: projectLeadPermission.TimeLog.deleteOther,
                Mtadd: projectLeadPermission.MyTask.addOther,
                Mtedit: projectLeadPermission.MyTask.editOther,
                Mtview: projectLeadPermission.MyTask.viewOther,
                Mtdelete: projectLeadPermission.MyTask.deleteOther,
                RoleId: ROLE_TYPE.LEAD,
                UserId: GetLoggedInUserID(),
                PermissionType: "Others"
            };

            // Data for Employee "self" (without "Other")
            const selfEmpDataToSave = {
                Meadd: employeePermission.ManageEmp.add,
                Meedit: employeePermission.ManageEmp.edit,
                Meview: employeePermission.ManageEmp.view,
                Medelete: employeePermission.ManageEmp.delete,
                Mrpadd: employeePermission.ManageRolesPermission.add,
                Mrpedit: employeePermission.ManageRolesPermission.edit,
                Mrpview: employeePermission.ManageRolesPermission.view,
                Mrpdelete: employeePermission.ManageRolesPermission.delete,
                Mpadd: employeePermission.ManageProject.add,
                Mpedit: employeePermission.ManageProject.edit,
                Mpview: employeePermission.ManageProject.view,
                Mpdelete: employeePermission.ManageProject.delete,
                Tladd: employeePermission.TimeLog.add,
                Tledit: employeePermission.TimeLog.edit,
                Tlview: employeePermission.TimeLog.view,
                Tldelete: employeePermission.TimeLog.delete,
                Mtadd: employeePermission.MyTask.add,
                Mtedit: employeePermission.MyTask.edit,
                Mtview: employeePermission.MyTask.view,
                Mtdelete: employeePermission.MyTask.delete,
                RoleId: ROLE_TYPE.EMPLOYEE,
                UserId: GetLoggedInUserID(),
                PermissionType: "Self"
            };

            // Data for Employee "others" (with "Other")
            const othersEmpDataToSave = {
                Meadd: employeePermission.ManageEmp.addOther,
                Meedit: employeePermission.ManageEmp.editOther,
                Meview: employeePermission.ManageEmp.viewOther,
                Medelete: employeePermission.ManageEmp.deleteOther,
                Mrpadd: employeePermission.ManageRolesPermission.addOther,
                Mrpedit: employeePermission.ManageRolesPermission.editOther,
                Mrpview: employeePermission.ManageRolesPermission.viewOther,
                Mrpdelete: employeePermission.ManageRolesPermission.deleteOther,
                Mpadd: employeePermission.ManageProject.addOther,
                Mpedit: employeePermission.ManageProject.editOther,
                Mpview: employeePermission.ManageProject.viewOther,
                Mpdelete: employeePermission.ManageProject.deleteOther,
                Tladd: employeePermission.TimeLog.addOther,
                Tledit: employeePermission.TimeLog.editOther,
                Tlview: employeePermission.TimeLog.viewOther,
                Tldelete: employeePermission.TimeLog.deleteOther,
                Mtadd: employeePermission.MyTask.addOther,
                Mtedit: employeePermission.MyTask.editOther,
                Mtview: employeePermission.MyTask.viewOther,
                Mtdelete: employeePermission.MyTask.deleteOther,
                RoleId: ROLE_TYPE.EMPLOYEE,
                UserId: GetLoggedInUserID(),
                PermissionType: "Others"
            };


            response = await UserRolesPermission.SaveAdminRolesPermission(selfAdminDataToSave);
            response = await UserRolesPermission.SaveAdminRolesPermission(othersAdminDataToSave);

            response = await UserRolesPermission.SaveEmployeeRolesPermission(selfEmpDataToSave);
            response = await UserRolesPermission.SaveEmployeeRolesPermission(othersEmpDataToSave);

            response = await UserRolesPermission.SavePLRolesPermission(selfPLDataToSave);
            response = await UserRolesPermission.SavePLRolesPermission(othersPLDataToSave);


            if (response.data && response.status === HTTPResponse.OK) {
                ShowToastMessage(RolesPermissionErrorMessage.PermissionSave, ToastMessageType.Success)
                setIsSave(response.data);
                ToggleLoader(false);
            }
            if (response.status === HTTPResponse.Unauthorized) {
                LogOut();
                ToggleLoader(false);
            }
            // }
        }
        catch {
            ToggleLoader(false);
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail)
        }
    }

    return (

        <>
            {roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD ?
                <>

                    <section className="breadcrumb-block">
                        {/* <BreadcrumbComponent />   */}
                    </section>
                    <section className="roles-permissions-body">
                        <div className="container-fluid">
                            <div className="roles-permissions" style={{ paddingBottom: "6rem" }}>
                                <div className="grid-sec mt-3">
                                    <div className="section-title-fbox">
                                        <div className="section-title">
                                            <h6>Admin</h6>
                                        </div>
                                        {/* <div className="save-cancel-sec">
                                            {((roleId === ROLE_TYPE.ADMIN && adminPermissionDefaultShow.ManageRolesPermission.edit === 1) ||
                                                (roleId === ROLE_TYPE.EMPLOYEE && employeePermissionDefaultShow.ManageRolesPermission.edit === 1)
                                                || (roleId === ROLE_TYPE.LEAD && projectLeadPermissionDefaultShow.ManageRolesPermission.edit === 1)) &&
                                                <button className="save-btn" onClick={SaveAdminRole} style={{ marginRight: "5px" }}>Save</button>}
                                            <button className="cancel-btn" onClick={LoadDefaultAdminPermission}>Cancel</button>
                                        </div> */}
                                    </div>
                                    {/* {((roleId === ROLE_TYPE.ADMIN && adminPermissionDefaultShow.ManageRolesPermission.view === 1) ||
                                        (roleId === ROLE_TYPE.EMPLOYEE && employeePermissionDefaultShow.ManageRolesPermission.view === 1)
                                        || (roleId === ROLE_TYPE.LEAD && projectLeadPermissionDefaultShow.ManageRolesPermission.view === 1)) && */}
                                    <div className="custom-grid-sec mt-2">
                                        <table className="table-responsive table-main">

                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th colSpan="4" className="td-th-alignment">Self</th>
                                                    <th colSpan="4" className="td-th-alignment">All Users</th>
                                                </tr>
                                                <th>Permission</th>
                                                <th className="td-th-alignment">Add</th>
                                                <th className="td-th-alignment">Edit</th>
                                                <th className="td-th-alignment">View (Grid)</th>
                                                <th className="td-th-alignment">Delete</th>
                                                <th className="td-th-alignment">Add</th>
                                                <th className="td-th-alignment">Edit</th>
                                                <th className="td-th-alignment">View (Grid)</th>
                                                <th className="td-th-alignment">Delete</th>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="permission-type">Manage Employees</td>
                                                    {/* <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.ManageEmp.add === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange("ManageEmp", "add"))} />
                                                                <span
                                                                    className={adminPermission.ManageEmp.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.ManageEmp.add === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.ManageEmp.edit === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange("ManageEmp", "edit"))} />
                                                                <span
                                                                    className={adminPermission.ManageEmp.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.ManageEmp.edit === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.ManageEmp.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.ManageEmp.view === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange("ManageEmp", "view"))} />
                                                                <span
                                                                    className={adminPermission.ManageEmp.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.ManageEmp.view === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.ManageEmp.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.ManageEmp.delete === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange("ManageEmp", "delete"))} />
                                                                <span
                                                                    className={adminPermission.ManageEmp.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.ManageEmp.delete === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.ManageEmp.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td> */}
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>

                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.ManageEmp.addOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("ManageEmp", "addOther"))} />
                                                            <span
                                                                className={adminPermission.ManageEmp.addOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageEmp.addOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.ManageEmp.addOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.ManageEmp.editOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("ManageEmp", "editOther"))} />
                                                            <span
                                                                className={adminPermission.ManageEmp.editOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageEmp.editOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.ManageEmp.editOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.ManageEmp.viewOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("ManageEmp", "viewOther"))} />
                                                            <span
                                                                className={adminPermission.ManageEmp.viewOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageEmp.viewOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.ManageEmp.viewOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.ManageEmp.deleteOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("ManageEmp", "deleteOther"))} />
                                                            <span
                                                                className={adminPermission.ManageEmp.deleteOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageEmp.deleteOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.ManageEmp.deleteOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="permission-type">
                                                        Manage Roles and Permissions
                                                    </td>
                                                    {/* <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.add === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange("ManageRolesPermission", "add"))} />
                                                                <span
                                                                    className={adminPermission.ManageRolesPermission.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.ManageRolesPermission.add === 1 ? "Active" : "Inactive"}
                                                                    style={{ cursor: 'no-drop' }}
                                                                />

                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={adminPermission.ManageRolesPermission.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.edit === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange("ManageRolesPermission", "edit"))} />
                                                                <span
                                                                    className={adminPermission.ManageRolesPermission.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.ManageRolesPermission.edit === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.ManageRolesPermission.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.view === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "view"))} />
                                                                <span
                                                                    className={adminPermission.ManageRolesPermission.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.ManageRolesPermission.view === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.ManageRolesPermission.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td> */}

                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" :"Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" :"Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>

                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.addOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "addOther"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.addOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.addOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.addOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.editOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("ManageRolesPermission", "editOther"))} />
                                                            <span
                                                                className={adminPermission.ManageRolesPermission.editOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.editOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.ManageRolesPermission.editOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.viewOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("ManageRolesPermission", "viewOther"))} />
                                                            <span
                                                                className={adminPermission.ManageRolesPermission.viewOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.viewOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.ManageRolesPermission.viewOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.deleteOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "deleteOther"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.deleteOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.deleteOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.deleteOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="permission-type">Manage Project</td>
                                                    {/* <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.ManageProject.add === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('ManageProject', "add"))} />
                                                                <span
                                                                    className={adminPermission.ManageProject.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.ManageProject.add === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.ManageProject.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.ManageProject.edit === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('ManageProject', "edit"))} />
                                                                <span
                                                                    className={adminPermission.ManageProject.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.ManageProject.edit === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.ManageProject.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.ManageProject.view === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('ManageProject', "view"))} />
                                                                <span
                                                                    className={adminPermission.ManageProject.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.ManageProject.view === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.ManageProject.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.ManageProject.delete === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('ManageProject', "delete"))} />
                                                                <span
                                                                    className={adminPermission.ManageProject.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.ManageProject.delete === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.ManageProject.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td> */}

                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>


                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.ManageProject.addOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("ManageProject", "addOther"))} />
                                                            <span
                                                                className={adminPermission.ManageProject.addOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageProject.addOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.ManageProject.addOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.ManageProject.editOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("ManageProject", "editOther"))} />
                                                            <span
                                                                className={adminPermission.ManageProject.editOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageProject.editOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.ManageProject.editOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.ManageProject.viewOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("ManageProject", "viewOther"))} />
                                                            <span
                                                                className={adminPermission.ManageProject.viewOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageProject.viewOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.ManageProject.viewOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.ManageProject.deleteOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("ManageProject", "deleteOther"))} />
                                                            <span
                                                                className={adminPermission.ManageProject.deleteOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageProject.deleteOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.ManageProject.deleteOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="permission-type">Time Logging</td>
                                                    {/* <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.TimeLog.add === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('TimeLog', "add"))} />
                                                                <span
                                                                    className={adminPermission.TimeLog.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.TimeLog.add === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.TimeLog.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.TimeLog.edit === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('TimeLog', "edit"))} />
                                                                <span
                                                                    className={adminPermission.TimeLog.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.TimeLog.edit === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.TimeLog.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.TimeLog.view === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('TimeLog', "view"))} />
                                                                <span
                                                                    className={adminPermission.TimeLog.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.TimeLog.view === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.TimeLog.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.TimeLog.delete === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('TimeLog', "delete"))} />
                                                                <span
                                                                    className={adminPermission.TimeLog.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.TimeLog.delete === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.TimeLog.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td> */}

                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>

                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.TimeLog.addOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("TimeLog", "addOther"))} />
                                                            <span
                                                                className={adminPermission.TimeLog.addOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.TimeLog.addOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.TimeLog.addOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.TimeLog.editOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("TimeLog", "editOther"))} />
                                                            <span
                                                                className={adminPermission.TimeLog.editOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.TimeLog.editOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.TimeLog.editOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.TimeLog.viewOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("TimeLog", "viewOther"))} />
                                                            <span
                                                                className={adminPermission.TimeLog.viewOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.TimeLog.viewOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.TimeLog.viewOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.TimeLog.deleteOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("TimeLog", "deleteOther"))} />
                                                            <span
                                                                className={adminPermission.TimeLog.deleteOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.TimeLog.deleteOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.TimeLog.deleteOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>


                                                </tr>
                                                <tr>
                                                    <td className="permission-type">My Task</td>
                                                    {/* <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.MyTask.add === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('MyTask', "add"))} />
                                                                <span
                                                                    className={adminPermission.MyTask.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.MyTask.add === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.MyTask.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.MyTask.edit === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('MyTask', "edit"))} />
                                                                <span
                                                                    className={adminPermission.MyTask.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.MyTask.edit === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.MyTask.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.MyTask.view === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('MyTask', "view"))} />
                                                                <span
                                                                    className={adminPermission.MyTask.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.MyTask.view === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.MyTask.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={adminPermission.MyTask.delete === 1}
                                                                    onChange={() => setAdminPermission(handleAdminStatusChange('MyTask', "delete"))} />
                                                                <span
                                                                    className={adminPermission.MyTask.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={adminPermission.MyTask.delete === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={adminPermission.MyTask.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td> */}


                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={adminPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={adminPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>


                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.MyTask.addOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("MyTask", "addOther"))} />
                                                            <span
                                                                className={adminPermission.MyTask.addOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.MyTask.addOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.MyTask.addOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.MyTask.editOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("MyTask", "editOther"))} />
                                                            <span
                                                                className={adminPermission.MyTask.editOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.MyTask.editOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.MyTask.editOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.MyTask.viewOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("MyTask", "viewOther"))} />
                                                            <span
                                                                className={adminPermission.MyTask.viewOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.MyTask.viewOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.MyTask.viewOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={adminPermission.MyTask.deleteOther === 1}
                                                                onChange={() => setAdminPermission(handleAdminStatusChange("MyTask", "deleteOther"))} />
                                                            <span
                                                                className={adminPermission.MyTask.deleteOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={adminPermission.MyTask.deleteOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={adminPermission.MyTask.deleteOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* {isAdminOptionEmptyError && (
                                    <>
                                        <ErrorField
                                            show={isAdminOptionEmptyError}
                                            message={RolesPermissionErrorMessage.AdminPermissionEmpty}
                                        />
                                    </>
                                )} */}
                                    </div>
                                    {/* } */}
                                </div>

                                <div className="grid-sec mt-3">
                                    <div className="section-title-fbox">
                                        <div className="section-title">
                                            <h6>Project Lead</h6>
                                        </div>
                                        {/* <div className="save-cancel-sec">
                                            {((roleId === ROLE_TYPE.ADMIN && adminPermissionDefaultShow.ManageRolesPermission.edit === 1) ||
                                                (roleId === ROLE_TYPE.EMPLOYEE && employeePermissionDefaultShow.ManageRolesPermission.edit === 1)
                                                || (roleId === ROLE_TYPE.LEAD && projectLeadPermissionDefaultShow.ManageRolesPermission.edit === 1)) &&
                                                <button className="save-btn" onClick={SavePLRole} style={{ marginRight: "5px" }}>Save</button>}
                                            <button className="cancel-btn" onClick={LoadDefaultPLPermission}>Cancel</button>
                                        </div> */}
                                    </div>
                                    {/* {((roleId === ROLE_TYPE.ADMIN && adminPermissionDefaultShow.ManageRolesPermission.view === 1) ||
                                        (roleId === ROLE_TYPE.EMPLOYEE && employeePermissionDefaultShow.ManageRolesPermission.view === 1)
                                        || (roleId === ROLE_TYPE.LEAD && projectLeadPermissionDefaultShow.ManageRolesPermission.view === 1)) && */}
                                    <div className="custom-grid-sec mt-2">
                                        <table className="table-responsive table-main">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th colSpan="4" className="td-th-alignment">Self</th>
                                                    <th colSpan="4" className="td-th-alignment">All Employees Under him</th>
                                                </tr>
                                                <th>Permission</th>

                                                <th className="td-th-alignment">Add</th>
                                                <th className="td-th-alignment">Edit</th>
                                                <th className="td-th-alignment">View (Grid)</th>
                                                <th className="td-th-alignment">Delete</th>

                                                <th className="td-th-alignment">Add</th>
                                                <th className="td-th-alignment">Edit</th>
                                                <th className="td-th-alignment">View (Grid)</th>
                                                <th className="td-th-alignment">Delete</th>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="permission-type">Manage Employees</td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageEmp.add === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageEmp', "add"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageEmp.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageEmp.add === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageEmp.edit === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageEmp', "edit"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageEmp.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageEmp.edit === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageEmp.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageEmp.view === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageEmp', "view"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageEmp.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageEmp.view === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageEmp.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageEmp.delete === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageEmp', "delete"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageEmp.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageEmp.delete === 1 ? "Active" : "Inactive"}
                                                            />
                                                            <span
                                                                className={projectLeadPermission.ManageEmp.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>

                                                    {/* <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox"
                                                                    onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageRolesPermission', "add"))} />
                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageEmp.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={projectLeadPermission.ManageEmp.add === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>

                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox"
                                                                    onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageRolesPermission', "add"))} />
                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageEmp.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={projectLeadPermission.ManageEmp.edit === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageEmp.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td> */}


                                                    {/* <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageEmp.editOther === 1}
                                                                    onChange={() => setProjectLeadPermission(handleRPStatusChange("ManageEmp", "editOther"))} />
                                                                <span
                                                                    className={projectLeadPermission.ManageEmp.editOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={projectLeadPermission.ManageEmp.editOther === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span
                                                                    className={projectLeadPermission.ManageEmp.editOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td> */}

                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageEmp.addOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("ManageEmp", "addOther"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageEmp.addOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageEmp.addOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageEmp.addOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageEmp.editOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("ManageEmp", "editOther"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageEmp.editOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageEmp.editOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageEmp.editOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageEmp.viewOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("ManageEmp", "viewOther"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageEmp.viewOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageEmp.viewOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageEmp.viewOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageEmp.deleteOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("ManageEmp", "deleteOther"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageEmp.deleteOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageEmp.deleteOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageEmp.deleteOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    {/* <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox"
                                                                    onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageEmp', "add"))} />
                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageEmp.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={projectLeadPermission.ManageEmp.delete === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageEmp.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td> */}

                                                </tr>
                                                <tr>
                                                    <td className="permission-type">
                                                        Manage Roles and Permissions
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageRolesPermission.add === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageRolesPermission', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={projectLeadPermission.ManageRolesPermission.add === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageRolesPermission.add === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={projectLeadPermission.ManageRolesPermission.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageRolesPermission.edit === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageRolesPermission', "edit"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageRolesPermission.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageRolesPermission.edit === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageRolesPermission.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageRolesPermission.view === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageRolesPermission', "view"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageRolesPermission.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageRolesPermission.view === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageRolesPermission.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageRolesPermission.delete === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageRolesPermission', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={projectLeadPermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageRolesPermission.delete === 1 ? "Active" : "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={projectLeadPermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>


                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageRolesPermission.addOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageRolesPermission', "addOther"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={projectLeadPermission.ManageRolesPermission.addOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageRolesPermission.addOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={projectLeadPermission.ManageRolesPermission.addOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    {/* <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageRolesPermission.addOther === 1}
                                                                    onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageRolesPermission', "addOther"))} />
                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageRolesPermission.addOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={projectLeadPermission.ManageRolesPermission.addOther === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageRolesPermission.addOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td> */}
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageRolesPermission.editOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("ManageRolesPermission", "editOther"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageRolesPermission.editOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageRolesPermission.editOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageRolesPermission.editOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>

                                                    {/* <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageRolesPermission.addOther === 1}
                                                                    onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageRolesPermission', "addOther"))} />
                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageRolesPermission.addOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={projectLeadPermission.ManageRolesPermission.addOther === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageRolesPermission.addOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>

                                                        <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageRolesPermission.editOther === 1}
                                                                    onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageRolesPermission', "editOther"))} />
                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageRolesPermission.editOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={projectLeadPermission.ManageRolesPermission.editOther === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageRolesPermission.editOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td> */}
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageRolesPermission.viewOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("ManageRolesPermission", "viewOther"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageRolesPermission.viewOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageRolesPermission.viewOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageRolesPermission.viewOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageRolesPermission.deleteOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageRolesPermission', "deleteOther"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={projectLeadPermission.ManageRolesPermission.deleteOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageRolesPermission.deleteOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={projectLeadPermission.ManageRolesPermission.deleteOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="permission-type">Manage Project</td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageProject.add === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageProject', "add"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageProject.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageProject.add === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageProject.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageProject.edit === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageProject', "edit"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageProject.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageProject.edit === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageProject.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageProject.view === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageProject', "view"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageProject.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageProject.view === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageProject.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageProject.delete === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageProject', "delete"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageProject.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageProject.delete === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageProject.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>


                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageProject.addOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("ManageProject", "addOther"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageProject.addOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageProject.addOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageProject.addOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageProject.editOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("ManageProject", "editOther"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageProject.editOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageProject.editOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageProject.editOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    {/* <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageProject.deleteOther === 1}
                                                                    onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageProject', "addOther"))} />
                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageProject.addOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={projectLeadPermission.ManageProject.addOther === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageProject.addOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td>
                                                          <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageProject.deleteOther === 1}
                                                                    onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageProject', "editOther"))} />
                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageProject.editOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={projectLeadPermission.ManageProject.editOther === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageProject.editOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td> */}
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageProject.viewOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("ManageProject", "viewOther"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageProject.viewOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageProject.viewOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageProject.viewOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageProject.deleteOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("ManageProject", "deleteOther"))} />
                                                            <span
                                                                className={projectLeadPermission.ManageProject.deleteOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.ManageProject.deleteOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.ManageProject.deleteOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    {/* <td className="toggle-col td-th-alignment">
                                                            <label className="toggle">
                                                                <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={projectLeadPermission.ManageProject.deleteOther === 1}
                                                                    onChange={() => setProjectLeadPermission(handleRPStatusChange('ManageProject', "add"))} />
                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageProject.deleteOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                    data-off=""
                                                                    data-on=""
                                                                    title={projectLeadPermission.ManageProject.deleteOther === 1 ? "Active" : "Inactive"}
                                                                />

                                                                <span style={{ cursor: 'no-drop' }}
                                                                    className={projectLeadPermission.ManageProject.deleteOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                                />
                                                            </label>
                                                        </td> */}
                                                </tr>
                                                <tr>
                                                    <td className="permission-type">Time Logging</td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.TimeLog.add === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('TimeLog', "add"))} />
                                                            <span
                                                                className={projectLeadPermission.TimeLog.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.TimeLog.add === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.TimeLog.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.TimeLog.edit === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('TimeLog', "edit"))} />
                                                            <span
                                                                className={projectLeadPermission.TimeLog.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.TimeLog.edit === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.TimeLog.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.TimeLog.view === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('TimeLog', "view"))} />
                                                            <span
                                                                className={projectLeadPermission.TimeLog.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.TimeLog.view === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.TimeLog.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.TimeLog.delete === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('TimeLog', "delete"))} />
                                                            <span
                                                                className={projectLeadPermission.TimeLog.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.TimeLog.delete === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.TimeLog.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>


                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.TimeLog.addOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("TimeLog", "addOther"))} />
                                                            <span
                                                                className={projectLeadPermission.TimeLog.addOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.TimeLog.addOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.TimeLog.addOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.TimeLog.editOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("TimeLog", "editOther"))} />
                                                            <span
                                                                className={projectLeadPermission.TimeLog.editOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.TimeLog.editOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.TimeLog.editOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.TimeLog.viewOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("TimeLog", "viewOther"))} />
                                                            <span
                                                                className={projectLeadPermission.TimeLog.viewOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.TimeLog.viewOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.TimeLog.viewOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.TimeLog.deleteOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("TimeLog", "deleteOther"))} />
                                                            <span
                                                                className={projectLeadPermission.TimeLog.deleteOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.TimeLog.deleteOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.TimeLog.deleteOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="permission-type">My Task</td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.MyTask.add === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('MyTask', "add"))} />
                                                            <span
                                                                className={projectLeadPermission.MyTask.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.MyTask.add === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.MyTask.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.MyTask.edit === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('MyTask', "edit"))} />
                                                            <span
                                                                className={projectLeadPermission.MyTask.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.MyTask.edit === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.MyTask.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.MyTask.view === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('MyTask', "view"))} />
                                                            <span
                                                                className={projectLeadPermission.MyTask.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.MyTask.view === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.MyTask.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.MyTask.delete === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange('MyTask', "delete"))} />
                                                            <span
                                                                className={projectLeadPermission.MyTask.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.MyTask.delete === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.MyTask.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>


                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.MyTask.addOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("MyTask", "addOther"))} />
                                                            <span
                                                                className={projectLeadPermission.MyTask.addOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.MyTask.addOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.MyTask.addOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.MyTask.editOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("MyTask", "editOther"))} />
                                                            <span
                                                                className={projectLeadPermission.MyTask.editOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.MyTask.editOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.MyTask.editOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.MyTask.viewOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("MyTask", "viewOther"))} />
                                                            <span
                                                                className={projectLeadPermission.MyTask.viewOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.MyTask.viewOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.MyTask.viewOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={projectLeadPermission.MyTask.deleteOther === 1}
                                                                onChange={() => setProjectLeadPermission(handleRPStatusChange("MyTask", "deleteOther"))} />
                                                            <span
                                                                className={projectLeadPermission.MyTask.deleteOther === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={projectLeadPermission.MyTask.deleteOther === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={projectLeadPermission.MyTask.deleteOther === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* {isEmployeeOptionEmptyError && (
                                    <>
                                        <ErrorField
                                            show={isEmployeeOptionEmptyError}
                                            message={RolesPermissionErrorMessage.EmployeePermissionEmpty}
                                        />
                                    </>
                                )} */}
                                    </div>
                                    {/* } */}
                                </div>

                                <div className="grid-sec mt-3">
                                    <div className="section-title-fbox">
                                        <div className="section-title">
                                            <h6>Employee</h6>
                                        </div>

                                    </div>
                                    {/* {((roleId === ROLE_TYPE.ADMIN && adminPermissionDefaultShow.ManageRolesPermission.view === 1) ||
                                        (roleId === ROLE_TYPE.EMPLOYEE && employeePermissionDefaultShow.ManageRolesPermission.view === 1)
                                        || (roleId === ROLE_TYPE.LEAD && projectLeadPermissionDefaultShow.ManageRolesPermission.view === 1)) && */}
                                    <div className="custom-grid-sec mt-2">
                                        <table className="table-responsive table-main">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th colSpan="4" className="td-th-alignment">Self</th>
                                                    <th colSpan="4" className="td-th-alignment">Other Employees</th>
                                                </tr>
                                                <th>Permission</th>
                                                <th className="td-th-alignment">Add</th>
                                                <th className="td-th-alignment">Edit</th>
                                                <th className="td-th-alignment">View (Grid)</th>
                                                <th className="td-th-alignment">Delete</th>

                                                <th className="td-th-alignment">Add</th>
                                                <th className="td-th-alignment">Edit</th>
                                                <th className="td-th-alignment">View (Grid)</th>
                                                <th className="td-th-alignment">Delete</th>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="permission-type">Manage Employees</td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            {/* <input className="toggle-input" type="checkbox" checked={employeePermission.ManageEmp.add === 1} */}
                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                // className={employeePermission.ManageEmp.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                className="toggle-label disable-toggle"
                                                                data-off=""
                                                                data-on=""
                                                                // title={employeePermission.ManageEmp.add === 1 ? "Active" : "Inactive"}
                                                                title= "Disable"	

                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            {/* <input className="toggle-input" type="checkbox" checked={employeePermission.ManageEmp.edit === 1}
                                                            onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "edit"))} />
                                                        <span
                                                            className={employeePermission.ManageEmp.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                            data-off=""
                                                            data-on=""
                                                            title={employeePermission.ManageEmp.edit === 1 ? "Active" : "Inactive"}
                                                        />

                                                        <span
                                                            className={employeePermission.ManageEmp.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                        /> */}
                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label disable-toggle"
                                                                data-off=""
                                                                data-on=""
                                                                title= "Disable"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            {/* <input className="toggle-input" type="checkbox" checked={employeePermission.ManageEmp.view === 1}
                                                            onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "view"))} />
                                                        <span
                                                            className={employeePermission.ManageEmp.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                            data-off=""
                                                            data-on=""
                                                            title={employeePermission.ManageEmp.view === 1 ? "Active" : "Inactive"}
                                                        />

                                                        <span
                                                            className={employeePermission.ManageEmp.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                        /> */}
                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label disable-toggle"
                                                                data-off=""
                                                                data-on=""
                                                                title= "Disable"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            {/* <input className="toggle-input" type="checkbox" checked={employeePermission.ManageEmp.delete === 1}
                                                            onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "delete"))} />
                                                        <span
                                                            className={employeePermission.ManageEmp.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                            data-off=""
                                                            data-on=""
                                                            title={employeePermission.ManageEmp.delete === 1 ? "Active" : "Inactive"}
                                                        />

                                                        <span
                                                            className={employeePermission.ManageEmp.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                        /> */}
                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label disable-toggle"
                                                                data-off=""
                                                                data-on=""
                                                                title= "Disable"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>


                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            {/* <input className="toggle-input" type="checkbox" checked={employeePermission.ManageEmp.add === 1} */}
                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                // className={employeePermission.ManageEmp.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                // title={employeePermission.ManageEmp.add === 1 ? "Active" : "Inactive"}
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="permission-type">
                                                        Manage Roles and Permissions
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            {/* <input className="toggle-input" type="checkbox" checked={employeePermission.ManageRolesPermission.add === 1}
                                                            onChange={() => setEmployeePermission(handleEmpStatusChange('ManageRolesPermission', "add"))} />
                                                        <span
                                                            className={employeePermission.ManageRolesPermission.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                            data-off=""
                                                            data-on=""
                                                            title={employeePermission.ManageRolesPermission.add === 1 ? "Active" : "Inactive"}
                                                        />

                                                        <span
                                                            className={employeePermission.ManageRolesPermission.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                        /> */}
                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label disable-toggle"
                                                                data-off=""
                                                                data-on=""
                                                                title= "Disable"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            {/* <input className="toggle-input" type="checkbox" checked={employeePermission.ManageRolesPermission.edit === 1}
                                                            onChange={() => setEmployeePermission(handleEmpStatusChange('ManageRolesPermission', "edit"))} />
                                                        <span
                                                            className={employeePermission.ManageRolesPermission.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                            data-off=""
                                                            data-on=""
                                                            title={employeePermission.ManageRolesPermission.edit === 1 ? "Active" : "Inactive"}
                                                        />

                                                        <span
                                                            className={employeePermission.ManageRolesPermission.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                        /> */}
                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label disable-toggle"
                                                                data-off=""
                                                                data-on=""
                                                                title= "Disable"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            {/* <input className="toggle-input" type="checkbox" checked={employeePermission.ManageRolesPermission.view === 1}
                                                            onChange={() => setEmployeePermission(handleEmpStatusChange('ManageRolesPermission', "view"))} />
                                                        <span
                                                            className={employeePermission.ManageRolesPermission.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                            data-off=""
                                                            data-on=""
                                                            title={employeePermission.ManageRolesPermission.view === 1 ? "Active" : "Inactive"}
                                                        />

                                                        <span
                                                            className={employeePermission.ManageRolesPermission.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                        /> */}
                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label disable-toggle"
                                                                data-off=""
                                                                data-on=""
                                                                title= "Disable"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            {/* <input disabled={true} className="toggle-input" type="checkbox" checked={employeePermission.ManageRolesPermission.delete === 1}
                                                            onChange={() => setEmployeePermission(handleEmpStatusChange('ManageRolesPermission', "delete"))} />
                                                        <span
                                                            className={employeePermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                            data-off=""
                                                            data-on=""
                                                            title={employeePermission.ManageRolesPermission.delete === 1 ? "Active" : "Inactive"}
                                                        />

                                                        <span
                                                            className={employeePermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                        /> */}
                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label disable-toggle"
                                                                data-off=""
                                                                data-on=""
                                                                title= "Disable"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>

                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}

                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="permission-type">Manage Project</td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={employeePermission.ManageProject.add === 1}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageProject', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={employeePermission.ManageProject.add === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={employeePermission.ManageProject.add === 1 ? "Active" :  "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={employeePermission.ManageProject.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={employeePermission.ManageProject.edit === 1}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageProject', "edit"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={employeePermission.ManageProject.edit === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={employeePermission.ManageProject.edit === 1 ? "Active" :  "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={employeePermission.ManageProject.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={employeePermission.ManageProject.view === 1}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageProject', "view"))} />
                                                            <span
                                                                className={employeePermission.ManageProject.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={employeePermission.ManageProject.view === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={employeePermission.ManageProject.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input style={{ cursor: 'no-drop' }} disabled={true} className="toggle-input" type="checkbox" checked={employeePermission.ManageProject.delete === 1}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageProject', "delete"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={employeePermission.ManageProject.delete === 1 ? "toggle-label checked" : "toggle-label disable-toggle"}
                                                                data-off=""
                                                                data-on=""
                                                                title={employeePermission.ManageProject.delete === 1 ? "Active" :  "Disable"}
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={employeePermission.ManageProject.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>

                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}

                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            {/* <input disabled={true} className="toggle-input" type="checkbox" checked={employeePermission.ManageRolesPermission.delete === 1}
                                                            onChange={() => setEmployeePermission(handleEmpStatusChange('ManageRolesPermission', "delete"))} />
                                                        <span
                                                            className={employeePermission.ManageRolesPermission.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                            data-off=""
                                                            data-on=""
                                                            title={employeePermission.ManageRolesPermission.delete === 1 ? "Active" : "Inactive"}
                                                        />

                                                        <span
                                                            className={employeePermission.ManageRolesPermission.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                        /> */}
                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="permission-type">Time Logging</td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={employeePermission.TimeLog.add === 1}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('TimeLog', "add"))} />
                                                            <span
                                                                className={employeePermission.ManageProject.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={employeePermission.ManageProject.add === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={employeePermission.ManageProject.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={employeePermission.TimeLog.edit === 1}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('TimeLog', "edit"))} />
                                                            <span
                                                                className={employeePermission.ManageProject.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={employeePermission.ManageProject.edit === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={employeePermission.ManageProject.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={employeePermission.TimeLog.view === 1}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('TimeLog', "view"))} />
                                                            <span
                                                                className={employeePermission.ManageProject.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={employeePermission.ManageProject.view === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={employeePermission.ManageProject.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={employeePermission.TimeLog.delete === 1}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('TimeLog', "delete"))} />
                                                            <span
                                                                className={employeePermission.ManageProject.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={employeePermission.ManageProject.delete === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={employeePermission.ManageProject.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>

                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}

                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="permission-type">My Task</td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={employeePermission.MyTask.add === 1}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('MyTask', "add"))} />
                                                            <span
                                                                className={employeePermission.MyTask.add === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={employeePermission.MyTask.add === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={employeePermission.MyTask.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                            {/* <input  className="toggle-input" type="checkbox"  checked={employeePermission.MyTask.add === 1}
                                                            onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                        <span
                                                            className="toggle-label"
                                                            data-off=""
                                                            data-on=""
                                                            title="Inactive"
                                                            
                                                        />

                                                        <span
                                                            className={"toggle-handle"}
                                                            
                                                        // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                        /> */}
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={employeePermission.MyTask.edit === 1}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('MyTask', "edit"))} />
                                                            <span
                                                                className={employeePermission.MyTask.edit === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={employeePermission.MyTask.edit === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={employeePermission.MyTask.edit === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                            {/* <input  className="toggle-input" type="checkbox"  checked={false}
                                                            onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                        <span
                                                            className="toggle-label"
                                                            data-off=""
                                                            data-on=""
                                                            title="Inactive"
                                                           
                                                        />

                                                        <span
                                                            className={"toggle-handle"}
                                                           
                                                        // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                        /> */}
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={employeePermission.MyTask.view === 1}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('MyTask', "view"))} />
                                                            <span
                                                                className={employeePermission.MyTask.view === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={employeePermission.MyTask.view === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={employeePermission.MyTask.view === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                            {/* <input  className="toggle-input" type="checkbox"  checked={false}
                                                            onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                        <span
                                                            className="toggle-label"
                                                            data-off=""
                                                            data-on=""
                                                            title="Inactive"
                                                            
                                                        />

                                                        <span
                                                            className={"toggle-handle"}
                                                            
                                                        // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                        /> */}
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">
                                                            <input className="toggle-input" type="checkbox" checked={employeePermission.MyTask.delete === 1}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('MyTask', "delete"))} />
                                                            <span
                                                                className={employeePermission.MyTask.delete === 1 ? "toggle-label checked" : "toggle-label"}
                                                                data-off=""
                                                                data-on=""
                                                                title={employeePermission.MyTask.delete === 1 ? "Active" : "Inactive"}
                                                            />

                                                            <span
                                                                className={employeePermission.MyTask.delete === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                            />
                                                            {/* <input  className="toggle-input" type="checkbox"  checked={false}
                                                            onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                        <span
                                                            className="toggle-label"
                                                            data-off=""
                                                            data-on=""
                                                            title="Inactive"
                                                            
                                                        />

                                                        <span
                                                            className={"toggle-handle"}
                                                            
                                                        // className={employeePermission.ManageEmp.add === 1 ? "toggle-handle checked" : "toggle-handle"}
                                                        /> */}
                                                        </label>
                                                    </td>

                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}

                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                    <td className="toggle-col td-th-alignment">
                                                        <label className="toggle">

                                                            <input style={{ cursor: 'no-drop' }} className="toggle-input" type="checkbox" disabled={true} checked={false}
                                                                onChange={() => setEmployeePermission(handleEmpStatusChange('ManageEmp', "add"))} />
                                                            <span style={{ cursor: 'no-drop' }}
                                                                className="toggle-label"
                                                                data-off=""
                                                                data-on=""
                                                                title="Inactive"
                                                            />

                                                            <span style={{ cursor: 'no-drop' }}
                                                                className={"toggle-handle"}
                                                            />
                                                        </label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* {isPLOptionEmptyError && (
                                    <>
                                        <ErrorField
                                            show={isPLOptionEmptyError}
                                            message={RolesPermissionErrorMessage.ProjectLeadPermissionEmpty}
                                        />
                                    </>
                                )} */}
                                    </div>
                                    {/* } */}
                                </div>

                            </div>
                            <div className="roles-permissions save-cancel-sec floating-btn-rolepermission" style={{ display: "flex", justifyContent: "flex-end" }}>
                                {((roleId === ROLE_TYPE.ADMIN && adminPermissionDefaultShow.ManageRolesPermission.edit === 1) ||
                                    (roleId === ROLE_TYPE.EMPLOYEE && employeePermissionDefaultShow.ManageRolesPermission.edit === 1)
                                    || (roleId === ROLE_TYPE.LEAD && projectLeadPermissionDefaultShow.ManageRolesPermission.edit === 1)) &&
                                    <button className="save-btn" onClick={SaveAllRolesPermission} style={{ marginRight: "5px" }}>Save</button>}
                                <button className="cancel-btn" onClick={LoadAllDefaultPermission}>Cancel</button>
                            </div>

                        </div>
                    </section></> : <ErrorPage />}

        </>
    );
};

export default RolesAndPermission;
