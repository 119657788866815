import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import { useNavigate } from "react-router-dom";
import { AddLocalStorageData, GetLocalStorageData, GetLoggedInUserID, RemoveLocalStorageData } from "../Helpers/Utilities";
import LetteredAvatar from "../Pages/Overview/LetteredAvatar";
import { HTTPResponse, ROLE_TYPE, ToastMessageType } from "../Helpers/Enums";
import { LoginService } from "../WebApiServices/Login.service";
import LogOutModal from "./LogOutModal";
import { UserRolesPermission } from "../WebApiServices/RolesPermission.service";
import { ManageUsersService } from "../WebApiServices/ManageUsers.Service";
import { ToastMessages } from "../Helpers/HelperText";
import DeleteModal from "./DeleteModal";
import $ from 'jquery';

const Header = () => {
  const navigate = useNavigate();
  const isProjectsActive = window.location.pathname === "/ManageProjects" || window.location.pathname === "/EditProject" || window.location.pathname.startsWith("/Overview/") || window.location.pathname.startsWith("/AddActionItem/") || window.location.pathname.startsWith("/EditActionItem/");
  const isUsersActive = window.location.pathname === "/ManageUsers" || window.location.pathname === "/AddEmployee" || window.location.pathname === "/RolesPermission" || window.location.pathname === "/EditEmployee";
  const isReportsActive = window.location.pathname === "/TimeLog" || window.location.pathname === "/AddTimeLogging";
  const isMyTaskActive = window.location.pathname === "/MyTasks";
  const isEtimationActive = window.location.pathname === "/GlobalEstimation";
  const contextMenuRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const [dropdownList, setDropdownList] = useState(false)
  const [showDirtyPopup, setShowDirtyPopup] = useState(false);
  const [path, setPath] = useState('');

  //SYJ SET Scroller Properties for Header Sticker.
  useEffect(() => {
    let isScrolling = false;

    $(window).scroll(function () {
      if (!isScrolling) {
        window.requestAnimationFrame(function () {
          if ($(window).scrollTop() <= 50) {
            $('#shrink').removeClass('hide-logo');
          } else {
            $('#shrink').addClass('hide-logo');
          }
          isScrolling = false;
        });
        isScrolling = true;
      }
    });

    $(window).scroll(function () {
      const scrollTop = Math.ceil($(this).scrollTop());
      if (scrollTop <= 50) {
        $('#shrink').removeClass('hide-logo');
      } else {
        $('#shrink').addClass('hide-logo');
      }
    });

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      $(window).off('scroll');
    };
  }, []);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
        setProfileView(false);
      }
      if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target)) {
        setDropdownList(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const profileImage = GetLocalStorageData("imagePath");
  const bgcolor = GetLocalStorageData("color");
  const userId = GetLocalStorageData("userId");
  const [showLogoutPopUp, setShowLogoutPopUp] = useState(false);
  const localStorageId = GetLocalStorageData("key");
  const projectId = GetLocalStorageData("key");
  const { LogOut } = useUIContext();
  const { ShowToastMessage, ToggleLoader, markDirty, clearDirty, appState } = useContext(AppContext);
  const [userRoleId, setuserRoleId] = useState(false);
  /* <summary>
  date: 06-06-2024
  Name: Mustakim
  description: Removed unnecessary service for roleId and used from local storage.
  <summary>*/
  useEffect(() => {
    const roleId = GetLocalStorageData("roleID");
    if (roleId) {
      setuserRoleId(roleId);
    }
  }, [userRoleId])

  //SYJ - User Roles Permission
  const [adminPermission, setAdminPermission] = useState({
    ManageEmp: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageRolesPermission: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageProject: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    TimeLog: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    MyTask: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    }
  });

  const [projectLeadPermission, setProjectLeadPermission] = useState({
    ManageEmp: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageRolesPermission: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageProject: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    TimeLog: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    MyTask: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    }
  });

  const [employeePermission, setEmployeePermission] = useState({
    ManageEmp: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageRolesPermission: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageProject: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    TimeLog: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    MyTask: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    }
  });


  const NavigateToProjectsPage = () => {
    setProfileView(false);
    navigate("/ManageProjects");
  };
  // const NavigateToCreateActionItem = () => {
  //   SetActionItemClear(true);
  //   navigate(`/CreateActionItem/${projectId}`);
  // };


  const NavigateToPath = async (pathvalue) => {
    //Check if isDirty to show navigation Confirmation Modal

    let isDirty = GetLocalStorageData("isDirty");

    if (appState.isDirty === true) {
      setShowDirtyPopup(true);
      setPath(pathvalue);
      AddLocalStorageData("pathvalue", pathvalue);
    } else {
      if (pathvalue === "/EditEmployee") {
        const userId = GetLoggedInUserID();
        const response = await ManageUsersService.GetUserById(userId);
        // console.log("response employee", response.data)
        AddLocalStorageData("isFromProfileClicked", true);
        if (response.data.length > 0) {
          navigate("/EditEmployee", {
            state: {
              employeeDetails: response.data.length > 0 ? response.data[0] : [], ProfileMode: true
            }
          });
        }
        clearDirty();
        // RemoveLocalStorageData("isDirty");
        setProfileView(false);
      }
      else if (pathvalue === "/Logout") {
        setProfileView(false);
        setShowLogoutPopUp(true);
        clearDirty();
        // RemoveLocalStorageData("isDirty");
      }
      else {
        clearDirty();
        // RemoveLocalStorageData("isDirty");
        setProfileView(false);
        setDropdownList(false);
        navigate(pathvalue);
      }
    }
  };

  const NavigateToUsersPage = () => {
    setProfileView(false);
    navigate("/ManageUsers");
  };
  const NavigateToReports = () => {

    setProfileView(false);
    setDropdownList(false)
    navigate("/TimeLog");
  };
  const NavigateToTimeTracking = () => {
    setProfileView(false);
    setDropdownList(false)
    navigate("/MonthlyTimeTracker");
  }
  const NavigateToMyTasks = () => {
    setProfileView(false);
    navigate("/MyTasks");
  };

  const onLogOutClick = async () => {

    // await LoginService.UserLogout(userId);

    // RemoveLocalStorageData("roleID");
    // RemoveLocalStorageData("userName");
    // RemoveLocalStorageData("userId");
    // RemoveLocalStorageData("color");
    // RemoveLocalStorageData("imagePath");
    // RemoveLocalStorageData("selectedSprint")
    // RemoveLocalStorageData("newlySelectedEmployee")
    // RemoveLocalStorageData("newlySelectedProjects")
    // RemoveLocalStorageData("projectName")
    // RemoveLocalStorageData("key")
    // RemoveLocalStorageData("ProjectAddDetails")
    // RemoveLocalStorageData("ProjectId")
    // RemoveLocalStorageData("ActionItemCount")
    // RemoveLocalStorageData("actionItemTitle")
    // RemoveLocalStorageData("activeTab")
    // RemoveLocalStorageData("ProjectsLeading")
    // RemoveLocalStorageData("newProjectId")
    // RemoveLocalStorageData("isFromProfileClicked");
    // RemoveLocalStorageData("empDetails");
    // RemoveLocalStorageData("pathvalue");
    // RemoveLocalStorageData("isDirty");
    // RemoveLocalStorageData("lastVisitedProjectIds");
    // RemoveLocalStorageData("activeTabGlobalEstimation");
    // RemoveLocalStorageData("locationHistory");
    // clearDirty();  

    setShowLogoutPopUp(false);
    LogOut();

    // navigate("/");
  };

  const NavigateToProject = () => {
    setProfileView(false);
    navigate("/ManageProjects ");
  }

  useEffect(() => {
    //GetAllUser();
    GetAllRolesPermission();
  }, []);


  // const GetAllUser = async () => {
  //   try {
  //     ToggleLoader(true);
  //     const response = await ManageUsersService.GetAllUsers();
  //     if (response.status === HTTPResponse.OK) {
  //       const userId = GetLocalStorageData("userId");
  //       const usersWithFullName = response.data.filter((user) => (user.employeeId === userId))
  //       let userRoleId = usersWithFullName[0].roleId;
  //       //alert(userRoleId);
  //       setuserRoleId(userRoleId);
  //       AddLocalStorageData("roleID", userRoleId);
  //       ToggleLoader(false);
  //     }
  //     if (response.status === HTTPResponse.Unauthorized) {
  //       LogOut();
  //     }
  //   } catch (error) {
  //     ToggleLoader(false);
  //     ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
  //   }
  // };


  //Set Roles Permission

  const GetAllRolesPermission = async () => {
    try {
      ToggleLoader(true);
      //Default Array llist to filter data
      let adminList = [];
      let adminOtherList = [];
      let leadList = [];
      let leadOtherList = [];
      let employeeList = [];
      let employeeOtherList = [];
      const responseDefaultRP = await UserRolesPermission.GetDefaultRolePermissions();
      const responseUserSaveRP = await UserRolesPermission.GetUserSavedRolePermissions();
      if (responseDefaultRP.status === HTTPResponse.OK && responseUserSaveRP.status === HTTPResponse.OK) {
        // Function to get the list of role IDs that exist in the user saved role permissions data
        const existingRoleIds = responseUserSaveRP.data.map(item => item.roleId);


        // Bind data based on the existing role IDs
        // Bind data based on the existing role IDs
        if (existingRoleIds.includes(1)) {
          adminList = responseUserSaveRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 1);
          adminOtherList = responseUserSaveRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 2);
        }
        if (existingRoleIds.includes(2)) {
          employeeList = responseUserSaveRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 1);
          employeeOtherList = responseUserSaveRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 2);
        }
        if (existingRoleIds.includes(3)) {
          leadList = responseUserSaveRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 1);
          leadOtherList = responseUserSaveRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 2);
        }

        // If no role IDs 1, 2, or 3 data is present, bind the default list
        if (adminList.length === 0) {
          adminList = responseDefaultRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 1);
        }
        if (employeeList.length === 0) {
          employeeList = responseDefaultRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 1);
        }
        if (leadList.length === 0) {
          leadList = responseDefaultRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 1);
        }

        if (adminList.length > 0) {
          // Update the adminPermission state with the filtered admin list
          setAdminPermission({
            ManageEmp: {
              add: adminList.some(item => item.meadd === 0) ? 0 : 1,
              edit: adminList.some(item => item.meedit === 0) ? 0 : 1,
              view: adminList.some(item => item.meview === 0) ? 0 : 1,
              delete: adminList.some(item => item.medelete === 0) ? 0 : 1,
            },
            ManageRolesPermission: {
              add: adminList.some(item => item.mrpadd === 0) ? 0 : 1,
              edit: adminList.some(item => item.mrpedit === 0) ? 0 : 1,
              view: adminList.some(item => item.mrpview === 0) ? 0 : 1,
              delete: adminList.some(item => item.mrpdelete === 0) ? 0 : 1,
            },
            ManageProject: {
              add: adminList.some(item => item.mpadd === 0) ? 0 : 1,
              edit: adminList.some(item => item.mpedit === 0) ? 0 : 1,
              view: adminList.some(item => item.mpview === 0) ? 0 : 1,
              delete: adminList.some(item => item.mpdelete === 0) ? 0 : 1,
            },
            TimeLog: {
              add: adminList.some(item => item.tladd === 0) ? 0 : 1,
              edit: adminList.some(item => item.tledit === 0) ? 0 : 1,
              view: adminList.some(item => item.tlview === 0) ? 0 : 1,
              delete: adminList.some(item => item.tldelete === 0) ? 0 : 1,
            },
            MyTask: {
              add: adminList.some(item => item.mtadd === 0) ? 0 : 1,
              edit: adminList.some(item => item.mtedit === 0) ? 0 : 1,
              view: adminList.some(item => item.mtview === 0) ? 0 : 1,
              delete: adminList.some(item => item.mtdelete === 0) ? 0 : 1,
            }
          });
        }

        if (leadList.length > 0) {
          // Update the adminPermission state with the filtered admin list
          setProjectLeadPermission({
            ManageEmp: {
              add: leadList.some(item => item.meadd === 0) ? 0 : 1,
              edit: leadList.some(item => item.meedit === 0) ? 0 : 1,
              view: leadList.some(item => item.meview === 0) ? 0 : 1,
              delete: leadList.some(item => item.medelete === 0) ? 0 : 1,
            },
            ManageRolesPermission: {
              add: leadList.some(item => item.mrpadd === 0) ? 0 : 1,
              edit: leadList.some(item => item.mrpedit === 0) ? 0 : 1,
              view: leadList.some(item => item.mrpview === 0) ? 0 : 1,
              delete: leadList.some(item => item.mrpdelete === 0) ? 0 : 1,
            },
            ManageProject: {
              add: leadList.some(item => item.mpadd === 0) ? 0 : 1,
              edit: leadList.some(item => item.mpedit === 0) ? 0 : 1,
              view: leadList.some(item => item.mpview === 0) ? 0 : 1,
              delete: leadList.some(item => item.mpdelete === 0) ? 0 : 1,
            },
            TimeLog: {
              add: leadList.some(item => item.tladd === 0) ? 0 : 1,
              edit: leadList.some(item => item.tledit === 0) ? 0 : 1,
              view: leadList.some(item => item.tlview === 0) ? 0 : 1,
              delete: leadList.some(item => item.tldelete === 0) ? 0 : 1,
            },
            MyTask: {
              add: leadList.some(item => item.mtadd === 0) ? 0 : 1,
              edit: leadList.some(item => item.mtedit === 0) ? 0 : 1,
              view: leadList.some(item => item.mtview === 0) ? 0 : 1,
              delete: leadList.some(item => item.mtdelete === 0) ? 0 : 1,
            }
          });
        }

        if (employeeList.length > 0) {
          // Update the adminPermission state with the filtered admin list
          setEmployeePermission({
            ManageEmp: {
              add: employeeList.some(item => item.meadd === 0) ? 0 : 1,
              edit: employeeList.some(item => item.meedit === 0) ? 0 : 1,
              view: employeeList.some(item => item.meview === 0) ? 0 : 1,
              delete: employeeList.some(item => item.medelete === 0) ? 0 : 1,
            },
            ManageRolesPermission: {
              add: employeeList.some(item => item.mrpadd === 0) ? 0 : 1,
              edit: employeeList.some(item => item.mrpedit === 0) ? 0 : 1,
              view: employeeList.some(item => item.mrpview === 0) ? 0 : 1,
              delete: employeeList.some(item => item.mrpdelete === 0) ? 0 : 1,
            },
            ManageProject: {
              add: employeeList.some(item => item.mpadd === 0) ? 0 : 1,
              edit: employeeList.some(item => item.mpedit === 0) ? 0 : 1,
              view: employeeList.some(item => item.mpview === 0) ? 0 : 1,
              delete: employeeList.some(item => item.mpdelete === 0) ? 0 : 1,
            },
            TimeLog: {
              add: employeeList.some(item => item.tladd === 0) ? 0 : 1,
              edit: employeeList.some(item => item.tledit === 0) ? 0 : 1,
              view: employeeList.some(item => item.tlview === 0) ? 0 : 1,
              delete: employeeList.some(item => item.tldelete === 0) ? 0 : 1,
            },
            MyTask: {
              add: employeeList.some(item => item.mtadd === 0) ? 0 : 1,
              edit: employeeList.some(item => item.mtedit === 0) ? 0 : 1,
              view: employeeList.some(item => item.mtview === 0) ? 0 : 1,
              delete: employeeList.some(item => item.mtdelete === 0) ? 0 : 1,
            }
          });
        }

        if (adminOtherList.length > 0) {
          // Update the adminPermission state with the filtered admin list
          setAdminPermission(prevState => ({
            ...prevState,
            ManageEmp: {
              ...prevState.ManageEmp,
              addOther: adminOtherList.some(item => item.meadd === 0) ? 0 : 1,
              editOther: adminOtherList.some(item => item.meedit === 0) ? 0 : 1,
              viewOther: adminOtherList.some(item => item.meview === 0) ? 0 : 1,
              deleteOther: adminOtherList.some(item => item.medelete === 0) ? 0 : 1,
            },
            ManageRolesPermission: {
              ...prevState.ManageRolesPermission,
              addOther: adminOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
              editOther: adminOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
              viewOther: adminOtherList.some(item => item.mrpview === 0) ? 0 : 1,
              deleteOther: adminOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
            },
            ManageProject: {
              ...prevState.ManageProject,
              addOther: adminOtherList.some(item => item.mpadd === 0) ? 0 : 1,
              editOther: adminOtherList.some(item => item.mpedit === 0) ? 0 : 1,
              viewOther: adminOtherList.some(item => item.mpview === 0) ? 0 : 1,
              deleteOther: adminOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
            },
            TimeLog: {
              ...prevState.TimeLog,
              addOther: adminOtherList.some(item => item.tladd === 0) ? 0 : 1,
              editOther: adminOtherList.some(item => item.tledit === 0) ? 0 : 1,
              viewOther: adminOtherList.some(item => item.tlview === 0) ? 0 : 1,
              deleteOther: adminOtherList.some(item => item.tldelete === 0) ? 0 : 1,
            },
            MyTask: {
              ...prevState.MyTask,
              addOther: adminOtherList.some(item => item.mtadd === 0) ? 0 : 1,
              editOther: adminOtherList.some(item => item.mtedit === 0) ? 0 : 1,
              viewOther: adminOtherList.some(item => item.mtview === 0) ? 0 : 1,
              deleteOther: adminOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
            }
          }));
        }

        if (leadOtherList.length > 0) {
          // Update the adminPermission state with the filtered admin list
          setProjectLeadPermission(prevState => ({
            ...prevState,
            ManageEmp: {
              ...prevState.ManageEmp,
              addOther: leadOtherList.some(item => item.meadd === 0) ? 0 : 1,
              editOther: leadOtherList.some(item => item.meedit === 0) ? 0 : 1,
              viewOther: leadOtherList.some(item => item.meview === 0) ? 0 : 1,
              deleteOther: leadOtherList.some(item => item.medelete === 0) ? 0 : 1,
            },
            ManageRolesPermission: {
              ...prevState.ManageRolesPermission,
              addOther: leadOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
              editOther: leadOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
              viewOther: leadOtherList.some(item => item.mrpview === 0) ? 0 : 1,
              deleteOther: leadOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
            },
            ManageProject: {
              ...prevState.ManageProject,
              addOther: leadOtherList.some(item => item.mpadd === 0) ? 0 : 1,
              editOther: leadOtherList.some(item => item.mpedit === 0) ? 0 : 1,
              viewOther: leadOtherList.some(item => item.mpview === 0) ? 0 : 1,
              deleteOther: leadOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
            },
            TimeLog: {
              ...prevState.TimeLog,
              addOther: leadOtherList.some(item => item.tladd === 0) ? 0 : 1,
              editOther: leadOtherList.some(item => item.tledit === 0) ? 0 : 1,
              viewOther: leadOtherList.some(item => item.tlview === 0) ? 0 : 1,
              deleteOther: leadOtherList.some(item => item.tldelete === 0) ? 0 : 1,
            },
            MyTask: {
              ...prevState.MyTask,
              addOther: leadOtherList.some(item => item.mtadd === 0) ? 0 : 1,
              editOther: leadOtherList.some(item => item.mtedit === 0) ? 0 : 1,
              viewOther: leadOtherList.some(item => item.mtview === 0) ? 0 : 1,
              deleteOther: leadOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
            }
          }));
        }

        if (employeeOtherList.length > 0) {
          // Update the adminPermission state with the filtered admin list
          setEmployeePermission(prevState => ({
            ManageEmp: {
              ...prevState.ManageEmp,
              addOther: employeeOtherList.some(item => item.meadd === 0) ? 0 : 1,
              editOther: employeeOtherList.some(item => item.meedit === 0) ? 0 : 1,
              viewOther: employeeOtherList.some(item => item.meview === 0) ? 0 : 1,
              deleteOther: employeeOtherList.some(item => item.medelete === 0) ? 0 : 1,
            },
            ManageRolesPermission: {
              ...prevState.ManageRolesPermission,
              addOther: employeeOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
              editOther: employeeOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
              viewOther: employeeOtherList.some(item => item.mrpview === 0) ? 0 : 1,
              deleteOther: employeeOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
            },
            ManageProject: {
              ...prevState.ManageProject,
              addOther: employeeOtherList.some(item => item.mpadd === 0) ? 0 : 1,
              editOther: employeeOtherList.some(item => item.mpedit === 0) ? 0 : 1,
              viewOther: employeeOtherList.some(item => item.mpview === 0) ? 0 : 1,
              deleteOther: employeeOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
            },
            TimeLog: {
              ...prevState.TimeLog,
              addOther: employeeOtherList.some(item => item.tladd === 0) ? 0 : 1,
              editOther: employeeOtherList.some(item => item.tledit === 0) ? 0 : 1,
              viewOther: employeeOtherList.some(item => item.tlview === 0) ? 0 : 1,
              deleteOther: employeeOtherList.some(item => item.tldelete === 0) ? 0 : 1,
            },
            MyTask: {
              ...prevState.MyTask,
              addOther: employeeOtherList.some(item => item.mtadd === 0) ? 0 : 1,
              editOther: employeeOtherList.some(item => item.mtedit === 0) ? 0 : 1,
              viewOther: employeeOtherList.some(item => item.mtview === 0) ? 0 : 1,
              deleteOther: employeeOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
            }
          }));
        }

        ToggleLoader(false);
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };


  const handleEditButton = async () => {
    const userId = GetLoggedInUserID();
    const response = await ManageUsersService.GetUserById(userId);
    // console.log("response employee", response.data)
    AddLocalStorageData("isFromProfileClicked", true);
    if (response.data.length > 0) {
      navigate("/EditEmployee", {
        state: {
          employeeDetails: response.data.length > 0 ? response.data[0] : [], ProfileMode: true
        }
      });
    }

    setProfileView(false);
  };

  const onDirtyModalCloseClick = () => {
    setShowDirtyPopup(false);
  };

  const onProceedClick = async () => {
    setShowDirtyPopup(false);
    let pathToNavigate = GetLocalStorageData("pathvalue");

    if (pathToNavigate !== undefined && pathToNavigate !== "" && pathToNavigate !== null && pathToNavigate !== "/Logout" && pathToNavigate !== "/EditEmployee") {
      navigate(pathToNavigate);
      clearDirty();
      // RemoveLocalStorageData("isDirty");
    }
    else if (pathToNavigate === "/Logout") {
      clearDirty();
      // RemoveLocalStorageData("isDirty");
      setShowLogoutPopUp(true);
    }
    else if (pathToNavigate === "/EditEmployee") {
      const userId = GetLoggedInUserID();
      const response = await ManageUsersService.GetUserById(userId);
      // console.log("response employee", response.data)
      AddLocalStorageData("isFromProfileClicked", true);
      if (response.data.length > 0) {
        navigate("/EditEmployee", {
          state: {
            employeeDetails: response.data.length > 0 ? response.data[0] : [], ProfileMode: true
          }
        });
      }
      clearDirty();
      // RemoveLocalStorageData("isDirty");
      setProfileView(false);
    };

  }

  const [profileView, setProfileView] = useState(false)
  return (

    <section className="main-header-admin" id="shrink">
      {/* Logout Modal */}
      {showLogoutPopUp && (
        <LogOutModal
          showDeleteModal={showLogoutPopUp}
          onYesClick={onLogOutClick}
          onNoClick={() => {
            setShowLogoutPopUp(false);
          }} />
      )}
      {showDirtyPopup && (
        <DeleteModal
          showDeleteModal={showDirtyPopup}
          isDirtyModal={true}
          onNoClick={onDirtyModalCloseClick}
          onYesClick={onProceedClick}
        />
      )}
      <div className="container-fluid">
        <div className="row main-header">
          <div className="col-8 col-sm-5 col-md-3 col-lg-3">
            <div className="logo-box">
              <a className="navbar-brand" style={{ cursor: "pointer" }}
                onClick={() => NavigateToPath("/ManageProjects")}>
                <img
                  src="../Assets/images/pulse-logo-onscroll.png"
                  className="logo-onscroll"
                  alt="edotLogo"
                  style={{ cursor: "pointer" }}
                  onClick={() => NavigateToPath("/ManageProjects")}
                />
                <img
                  src="../Assets/images/pulse-logo.png"
                  className="img-fluid og-logo"
                  alt="edotLogo"
                  style={{ cursor: "pointer", height: "60px" }}
                  onClick={() => NavigateToPath("/ManageProjects")}
                />
              </a>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-9 menu-options">
            {/* <nav className="navbar navbar-expand-lg navbar-light" style={{ zIndex: '999999' }}> */}
            <nav className="navbar navbar-expand-lg navbar-light" style={{ zIndex: '999' }}>
              <button
                className="navbar-toggler nav-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav menu-ul">
                  {(userRoleId === ROLE_TYPE.ADMIN
                    ///&& adminPermission.ManageEmp.view === 1
                  )
                    // || userRoleId === ROLE_TYPE.LEAD && projectLeadPermission.ManageEmp.view === 1) 
                    && (
                      <li className="nav-item nav-margin" >
                        <div className="header-profile-sec" >

                          <button
                            className={`nav-link ${isEtimationActive ? "active-page" : ""} header-projects`}
                            onClick={() => NavigateToPath("/GlobalEstimation")}
                            aria-expanded="false"
                            style={{ color: "white", fontFamily: "inherit", display: 'flex', alignItems: 'center' }}
                          >
                            Estimation
                          </button>
                          {/* {dropdownList && (<div className="dropdown-menu show" style={{ padding: '2px' }}>
                        <div className="dropdown">
                          <div className="button-container">
                            <button className="daily-button" style={{ border: 'none' }} onClick={() => NavigateToPath("/TimeLog")}>Daily</button>
                            <button className="monthly-button" style={{ border: 'none' }} onClick={() => NavigateToPath("/MonthlyTimeTracker")}>Weekly / Monthly</button>
                          </div>
                        </div>
                      </div>)} */}
                        </div>
                      </li>)}




                  {(userRoleId === ROLE_TYPE.ADMIN || userRoleId !== ROLE_TYPE.ADMIN && adminPermission.TimeLog.viewOther === 1 ||
                    userRoleId === ROLE_TYPE.LEAD && projectLeadPermission.TimeLog.view === 1 ||
                    userRoleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.TimeLog.viewOther === 1 ||
                    userRoleId === ROLE_TYPE.EMPLOYEE && employeePermission.TimeLog.view === 1) && (
                      <li className="nav-item nav-margin" >
                        <div className="header-profile-sec" ref={dropdownMenuRef}>

                          <button
                            className={`nav-link ${isReportsActive ? "active-page" : ""} header-projects`}
                            onClick={() => setDropdownList(!dropdownList)}
                            aria-expanded="false"
                            style={{ color: "white", fontFamily: "inherit", display: 'flex', alignItems: 'center' }}
                          >
                            Time Logging
                            <i className={`fas fa-chevron-${dropdownList ? 'down' : 'down'}`} style={{ marginLeft: '4px', verticalAlign: 'middle' }}></i>
                          </button>
                          {dropdownList && (<div className="dropdown-menu show" style={{ padding: '2px' }}>
                            <div className="dropdown">
                              <div className="button-container">
                                <button className="daily-button" style={{ border: 'none' }} onClick={() => NavigateToPath("/TimeLog")}>Daily</button>
                                <button className="monthly-button" style={{ border: 'none' }} onClick={() => NavigateToPath("/MonthlyTimeTracker")}>Weekly / Monthly</button>
                              </div>
                            </div>
                          </div>)}</div>
                      </li>
                    )}

                  {/* {(userRoleId === ROLE_TYPE.ADMIN ||
                    userRoleId === ROLE_TYPE.LEAD)  */}
                  {(userRoleId === ROLE_TYPE.ADMIN || userRoleId !== ROLE_TYPE.ADMIN && adminPermission.MyTask.viewOther === 1 ||
                    userRoleId === ROLE_TYPE.LEAD && projectLeadPermission.MyTask.view === 1 ||
                    userRoleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.MyTask.viewOther === 1

                  ) && (
                      <li className="nav-item nav-margin">
                        <button
                          className={`nav-link ${isMyTaskActive ? "active-page" : ""
                            } header-projects`}
                          onClick={() => NavigateToPath("/MyTasks")}

                          aria-expanded="false"
                          style={{ color: "white", fontFamily: "inherit" }}
                        >
                          My Tasks
                        </button>
                      </li>
                    )}
                  {/* {(userRoleId === ROLE_TYPE.ADMIN ||
                    userRoleId === ROLE_TYPE.LEAD) && ( */}
                  {(userRoleId === ROLE_TYPE.ADMIN || userRoleId !== ROLE_TYPE.ADMIN && adminPermission.ManageEmp.viewOther === 1 ||
                    userRoleId === ROLE_TYPE.LEAD && projectLeadPermission.ManageEmp.view === 1 ||
                    userRoleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.ManageEmp.viewOther === 1 ||
                    userRoleId === ROLE_TYPE.EMPLOYEE && employeePermission.ManageEmp.viewOther === 1) && (
                      <li className="nav-item nav-margin">
                        <button
                          className={`nav-link ${isUsersActive ? "active-page" : ""
                            } header-projects`}
                          onClick={() => NavigateToPath("/ManageUsers")}

                          aria-expanded="false"
                          style={{ color: "white", fontFamily: "inherit" }}
                        >
                          Employees
                        </button>
                      </li>
                    )}

                  {(userRoleId === ROLE_TYPE.ADMIN || userRoleId !== ROLE_TYPE.ADMIN && adminPermission.ManageProject.viewOther === 1 ||
                    userRoleId === ROLE_TYPE.LEAD && projectLeadPermission.ManageProject.view === 1 ||
                    userRoleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.ManageProject.viewOther === 1 ||
                    userRoleId === ROLE_TYPE.EMPLOYEE && employeePermission.ManageProject.view === 1) && (
                      <li className="nav-item nav-margin">
                        <button
                          className={`nav-link ${isProjectsActive ? "active-page" : ""
                            } header-projects`}
                          onClick={() => NavigateToPath("ManageProjects")}

                          aria-expanded="false"
                          style={{ color: "white", fontFamily: "inherit" }}
                        >
                          Projects
                        </button>
                      </li>)}

                  {/* {localStorageId && (
                      <li className="nav-item nav-margin">
                        <button
                          className="nav-bar header-create-btn"
                          onClick={NavigateToCreateActionItem}
                        >
                          <span style={{ color: "white" }}>Create</span>
                        </button>
                      </li>
                    )} */}
                </ul>
                <ul className="navbar-nav" >
                  <li className="nav-item" ref={contextMenuRef}>
                    <div className="header-profile-sec" style={{ cursor: "pointer" }}>

                      <div style={{ display: "flex" }}>

                        <div
                          //onClick={() => navigate("/Profile")}
                          onClick={() => { setProfileView(!profileView) }}
                        >
                          {profileImage != undefined && profileImage != "" && profileImage != null ?

                            <img style={{
                              display: "flex",
                              height: "38px",
                              width: "40px",
                              borderRadius: '50%',
                              objectFit: 'cover'
                            }} src={profileImage} srcSet={`${profileImage} 1x, ${profileImage} 2x`} alt="Profile" ></img>
                            : <LetteredAvatar
                              header={true}
                              name={GetLocalStorageData("userName")}
                              customStyle={{
                                display: "flex",
                                height: "38px",
                                width: "40px",
                                borderRadius: '50%',
                                color: "white",
                              }}
                              bgcolor={bgcolor}
                            />}
                        </div>

                      </div>



                      {profileView && (<div className="profile-menu-sec dropdown-menu show" style={{ cursor: 'default' }}>
                        <div className="profile-name-fbox">
                          <div>
                            <div className="menu-profile">
                              {/* <span>SN</span> */}
                              <span> {profileImage != undefined && profileImage != "" && profileImage != null ?

                                <img style={{
                                  display: "flex",
                                  height: "45px",
                                  width: "49px",
                                  borderRadius: '50%',
                                  objectFit: 'cover'
                                }} src={profileImage} alt="Profile" ></img>
                                : <LetteredAvatar
                                  header={true}
                                  name={GetLocalStorageData("userName")}
                                  customStyle={{
                                    display: "flex",
                                    height: "45px",
                                    width: "49px",
                                    borderRadius: '50%',
                                    color: "white",
                                  }}
                                  bgcolor={bgcolor}
                                />}</span>
                            </div>
                          </div>
                          <div className="name-gmail-sec">
                            <h3 className="profile-name"> {GetLocalStorageData("userName")}</h3>
                            <p className="profile-email">{GetLocalStorageData("email")}</p>
                          </div>
                        </div>

                        <div className="view-logout-fbox">
                          <div className="view-profile">
                            <a onClick={() => NavigateToPath("/EditEmployee")}>View Profile</a>
                          </div>
                          <div className="logout-sec">
                            <a onClick={() => NavigateToPath("/Logout")
                              // setShowLogoutPopUp(true);
                            }>Logout</a>
                          </div>
                        </div>
                      </div>)}
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>


  );
};

export default Header;
