import React, { useContext, useEffect } from "react";
import { ReportService } from "../WebApiServices/Report.service";
import {
  ActionItemTypes,
  HTTPResponse,
  ToastMessageType,
} from "../Helpers/Enums";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import { useState } from "react";
import Select from "react-select";
import ErrorField from "../Components/ErrorField";

import {
  AddEditActionItemErrorMsg,
  CommonErrorMessages,
  ToastMessages,
} from "../Helpers/HelperText";
import { ActionItemService } from "../WebApiServices/ActionItem.service";
import { useNavigate } from "react-router-dom";

const ProjectSelectionModal = ({
  showProjectsOptions,
  onYesClick,
  onNoClick,
  onProjectSelect,
}) => {
  const { ShowToastMessage, ToggleLoader } = useContext(AppContext);
  const { LogOut } = useUIContext();
  const [activeRadio, setActiveRadio] = useState("");
  const [selectedProject, setSelectedProject] = useState({});
  const [defaultKeys, setDefaultKeys] = useState([]);
  // const [selectedWOrkItenType,setSelectedWorkItemType]
  const [error, setError] = useState({
    project: false,
    type: false,
    workItemType: false,
  });
  const [workItem, setWorkItem] = useState();
  //let key;
  const [key, setKey] = useState("");
  const navigate = useNavigate();
  const getDefaultTasks = async (projectId) => {
    try {
      ToggleLoader(true);
      
      const response = await ActionItemService.GetDefaultTasks(projectId);
      if (response.status === HTTPResponse.OK) {
        setDefaultKeys(response.data);
      }

      if (response.status === HTTPResponse.Unauthorized) {
        LogOut();
      }
      ToggleLoader(false);
    } catch (error) {
      ToggleLoader(false);
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };
  const [projects, setProjects] = useState([]);
  const HandleDropdownChange = (e, type) => {
  
    if (type === "project") {
      if (e !== null && e !== undefined) {
        setError((prev) => ({
          ...prev,
          project: false,
        }));
        setSelectedProject(e);
        getDefaultTasks(e.value);
      } else {
        setSelectedProject("");
        setError((prev) => ({
          ...prev,
          project: true,
        }));
      }
    }
   
    if (type === "typeDrpDwn") {
      if (activeRadio === "WorkItem") {
        setWorkItem(e.value);
      } else if (activeRadio === "Misc") {
        ///key=defaultKeys && defaultKeys.find(item => item.item1 === e.value)?.item2;
        setWorkItem(e.value);
        
        setKey(
          defaultKeys &&
            defaultKeys.find((item) => item.item1 === e.value)?.item2
        );
      }
      setError((prev) => ({
        ...prev,
        workItemType: false,
      }));
    }

    // onYesClick(e.value);
  };
  useEffect(() => {
    fetchDropDownData();
  }, []);
  const fetchDropDownData = async () => {
    try {
      ToggleLoader(true);

      let response = await ReportService.GetWorklogDropDownData(1, 1, false,false);
      var data;
      if (response.status === HTTPResponse.OK) {
        data = response.data;
        // setEmployees(data.employees);
        setProjects(data.projects);
        ToggleLoader(false);
      } else {
        if (response.status === HTTPResponse.Unauthorized) {
          LogOut();
        }
        ToggleLoader(false);
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  const validate = () => {
   
    let result = true;
    if (!selectedProject.value) {
      setError((prev) => ({
        ...prev,
        project: true,
      }));
      result = false;
    }
    if (activeRadio === "") {
      setError((prev) => ({
        ...prev,
        type: true,
      }));
      result = false;
    }
    if (!workItem ) {
      setError((prev) => ({
        ...prev,
        workItemType: true,
      }));
      result = false;
    }
    // if(!key){
    //   setError((prev)=>({
    //     ...prev, workItemType:true
    //   }))
    //   result=true;
    // }
    return result;
  };
  const handleProceed = () => {
  
    if(validate()){
  
    // if (activeRadio === "" || !selectedProject.value > 0) {
    //   setError(true);
    //   return
    // }
    if (activeRadio === "WorkItem" && selectedProject.value > 0) {
      onProjectSelect(selectedProject, workItem);
    } else if (activeRadio === "Misc" && selectedProject.value > 0 && key) {
      // const isMisc = {
      //   ...selectedProject, Misc: "misc"
      // }
      // onProjectSelect(isMisc);
      navigate(`/EditActionItem/${key}`);
    }
    }

    // else {
    //   setError(true);
    // }
  };
  const handleActiveRadio = (type) => () => {
    setError((prev) => ({
      ...prev,
      type: false,
    }));
    if(type !=activeRadio){
      setWorkItem(null)
    }
    
    setActiveRadio(type);
  };
  return (
    <>
      <section>
        {/* <div className="add-task-my-task">
          <div
            className="modal-backdrop fade show"
            style={{ display: "block" }}
          ></div>
          <div
            className="modal modal-depth"
            id="deleteDoc-modal"
            style={{ display: showProjectsOptions ? "block" : "none" }}
          >
            <div className="modal-dialog modal-l modal-dialog-centered modal-box">
              <div className="modal-content">
                <div className="modal-body" style={{ marginBottom: "0px" }}>
                  <div className="container">
                    <div className="row modal-headerr">
                      <div className="col-10 col-md-10 col-lg-10">
                        <label className="modal-head-label">
                          Add Work Item
                        </label>
                      </div>
                      <div className="col-2 col-md-2 col-lg-2">
                        <button
                          type="button"
                          className="btn-close btn-close-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                    </div>
                  </div>

                  <>
                    <div className="block1">Project</div>
                    <Select
                      name="projectList"
                      placeholder="Please Select"
                     
                      value={selectedProject.value > 0 ? selectedProject : null}
                      options={projects}
                      onChange={(e) => {
                        HandleDropdownChange(e, "project");
                      }}
                      isClearable={
                        selectedProject !== undefined ||
                        (!selectedProject === null &&
                          selectedProject?.value > 0)
                      }
                    />
                    <ErrorField
                      show={error.project}
                      message={CommonErrorMessages.SelectProject}
                    ></ErrorField>
                  </>
                  <div className="row">
                    <div className="">
                      <label>
                        Type <span className="mandatory-field">*</span>
                      </label>
                      <div className="row">
                        <div className="col-5 col-sm-4 col-md-6 col-lg-6">
                          <input
                            type="radio"
                            name="taskType"
                            onClick={handleActiveRadio("WorkItem")}
                            checked={activeRadio === "WorkItem"}
                          />
                          <span className="task-type">Work Item</span>
                        </div>
                        <div className="col-7 col-sm-6 col-md-6 col-lg-6">
                          <input
                            type="radio"
                            name="taskType"
                            onClick={handleActiveRadio("Misc")}
                            checked={activeRadio === "Misc"}
                          />
                          <span className="task-type">
                            Miscellaneous Project Tasks
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ErrorField
                    show={error.type}
                    message={CommonErrorMessages.Type}
                  ></ErrorField>

                  <div className="">
                    <label>
                      Work Item Type <span className="mandatory-field">*</span>
                    </label>
                    <Select
                      inputId="typeDrpDwn"
                      placeholder="Please Select"
                      
                      options={
                        activeRadio === "WorkItem"
                          ? ActionItemTypes.filter(
                              (a) =>
                                a.value != 7 &&
                                a.value != 8 &&
                                a.value != 9 &&
                                a.value != 10 &&
                                a.value != 11
                            )
                          : ActionItemTypes.filter(
                              (a) =>
                                a.value != 1 &&
                                a.value != 2 &&
                                a.value != 3 &&
                                a.value != 6
                            )
                      }
                      onChange={(e) => HandleDropdownChange(e, "typeDrpDwn")}
                      formatOptionLabel={(p) => (
                        <div>
                          <img
                            width={"20px"}
                            height={"20px"}
                            src={p?.imagePath}
                            alt=""
                            style={{ marginRight: "3px" }}
                          />
                          <span>{p.label}</span>
                        </div>
                      )}
                       />
                    <ErrorField
                      show={error.workItemType}
                      message={AddEditActionItemErrorMsg.IssueType}
                    />
                  </div>
                
                  <div className="text-center yes-no-btn">
                    <div>
                      {" "}
                      <button
                        type="button"
                        className="proceed-btn"
                        onClick={(e) => {
                          handleProceed();
                        }}
                      >
                        Proceed
                      </button>
                    </div>

                    <div>
                      <button
                        type="button"
                        className="proceed-cancel-btn"
                        onClick={onNoClick}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="user-management-modal add-task-modal">
          <div
            className="modal fade show"
            id="userManagementModal"
            tabIndex={-1}
            aria-labelledby="ModalFormLabel"
            aria-hidden="true"
            style={{
              display: "block",
              backgroundColor: "#00000094",
              zIndex: "999!important",
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-custom">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="container">
                    <div className="row modal-headerr">
                      <div className="col-10 col-md-10 col-lg-10">
                        <label className="modal-head-label">
                        Add Task
                        </label>
                      </div>
                      <div className="col-2 col-md-2 col-lg-2">
                        <button
                          type="button"
                          className="btn-close btn-close-white"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                           onClick={onNoClick}
                        />
                      </div>
                    </div>
                    <div className="user-management-main">
                      <div
                        className="modal-body"
                        style={{ marginBottom: "0px" }}
                      >
                        <div className="">
                          <>
                           
                            <label>
                            Project <span className="mandatory-field">*</span>
                            </label>
                              
                          
                            <Select
                              name="projectList"
                              placeholder="Please Select"
                              // isDisabled={selectedMisc.value > 0}
                              value={
                                selectedProject.value > 0
                                  ? selectedProject
                                  : null
                              }
                              options={projects}
                              onChange={(e) => {
                                HandleDropdownChange(e, "project");
                              }}
                              isClearable={
                                selectedProject !== undefined ||
                                (!selectedProject === null &&
                                  selectedProject?.value > 0)
                              }
                            />
                            <ErrorField
                              show={error.project}
                              message={CommonErrorMessages.SelectProject}
                            ></ErrorField>
                          </>
                          <div className="mt-3">
                            <label>
                              Type <span className="mandatory-field">*</span>
                            </label>
                            <div className="row">
                              <div className="col-5 col-sm-4 col-md-6 col-lg-6">
                                <input
                                style={{marginRight:'5px'}}
                                  type="radio"
                                  name="taskType"
                                  onClick={handleActiveRadio("WorkItem")}
                                  checked={activeRadio === "WorkItem"}
                                />
                                <span className="task-type">Work Item</span>
                              </div>
                              <div className="col-7 col-sm-6 col-md-6 col-lg-6">
                                <input
                                style={{marginRight:'5px'}}
                                  type="radio"
                                  name="taskType"
                                  onClick={handleActiveRadio("Misc")}
                                  checked={activeRadio === "Misc"}
                                />
                                <span className="task-type">
                                  Miscellaneous Project Tasks
                                </span>
                              </div>
                            </div>
                          </div>

                          <ErrorField
                            show={error.type}
                            message={CommonErrorMessages.Type}
                          ></ErrorField>

                         {/* {activeRadio !=="" && ( */}
                           <div className="mt-3">
                            <label>
                              Work Item Type{" "}
                              <span className="mandatory-field">*</span>
                            </label>
                            <Select
                              inputId="typeDrpDwn"
                              placeholder="Please Select"
                              value={ActionItemTypes.find(a => a.value === workItem) || null}

                              options={
                                activeRadio === "WorkItem"
                                  ? ActionItemTypes.filter(
                                      (a) =>
                                        a.value != 7 &&
                                        a.value != 8 &&
                                        a.value != 9 &&
                                        a.value != 10 &&
                                        a.value != 11
                                    )
                                  : ActionItemTypes.filter(
                                      (a) =>
                                        a.value != 1 &&
                                        a.value != 2 &&
                                        a.value != 3 &&
                                        a.value != 6
                                    )
                              }
                              onChange={(e) =>
                                HandleDropdownChange(e, "typeDrpDwn")
                              }
                              formatOptionLabel={(p) => (
                                <div>
                                  <img
                                    width={"20px"}
                                    height={"20px"}
                                    src={p?.imagePath}
                                    alt=""
                                    style={{ marginRight: "3px" }}
                                  />
                                  <span>{p.label}</span>
                                </div>
                              )}
                              //isDisabled={actionItemDetails.isDefault}
                              //  isClearable={actionItemDetails.typeDrpDwn !== undefined || (!actionItemDetails.typeDrpDwn === null && actionItemDetails?.typeDrpDwn?.value > 0)}
                            />
                            <ErrorField
                              show={error.workItemType}
                              message={AddEditActionItemErrorMsg.workItemType}
                            />
                          </div>
                          
                          {/* )} */}
                          <div className="save-cancel-sec">
                            {" "}
                            <button
                              type="button"
                              className="proceed-btn"
                              onClick={(e) => {
                                handleProceed();
                              }}
                            >
                              Proceed
                            </button>
                            <button
                              type="button"
                              className="proceed-cancel-btn"
                              onClick={onNoClick}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
    </>
  );
};

export default ProjectSelectionModal;
